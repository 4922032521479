import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import RangeSlider from '../../components/RangeSlider/RangeSlider';
import Button from '../../components/Button/Button';
import styles from './ComparePlanModal.module.scss';
import Constants from '../../utils/Constants';
import Utils from '../../utils/Utils';

const { currencySymbols, clicksValueMap } = Constants;

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
    overflow: 'auto',
    padding: '40px 0'
  },
  content: {
    top: 20,
    right: 0,
    left: 0,
    bottom: 0,
    width: 551,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    padding: '40px 45px',
    position: 'relative'
  }
};

class ChangePlanModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isActive: true,
      proClicks: 10000,
      billingOptions: [],
      selectedBilling: this.props.currentPlan || {}
    };
  }

  handleCloseModal = () => {
    const { toggleModal } = this.props;
    toggleModal();
  };

  setBillingOptions = () => {
    console.log(this.props.currentPlan);
    const { plans } = this.props;
    const options = plans.map(plan => {
      return {
        id: plan.id,
        plan: plan.nickname.split(' - ')[0],
        nickname: plan.nickname,
        trial_period_days: plan.trial_period_days || 30,
        interval: plan.interval,
        interval_count: plan.interval_count,
        price: plan.amount / 100,
        clicks: parseInt(plan.metadata.clicks, 10),
        domains: plan.metadata.domains
      };
    });
    const defaultSelectedBilling = options.find(item =>
      this.props.currentPlan
        ? item.id === this.props.currentPlan.id
        : item.plan === 'Pro' && item.interval === 'month' && item.interval_count === 1
    );
    let defaultProClicks = defaultSelectedBilling.clicks;
    if (
      !options.find(
        item =>
          item.plan === 'Pro' &&
          item.interval === 'year' &&
          item.clicks === defaultSelectedBilling.clicks
      )
    ) {
      defaultProClicks = 10000;
    }
    this.setState({
      billingOptions: options,
      selectedBilling: defaultSelectedBilling,
      proClicks:
        this.props.currentPlan && this.props.currentPlan.nickname.includes('Pro')
          ? Number(this.props.currentPlan.metadata.clicks)
          : defaultProClicks
    });
  };

  componentDidMount = () => {
    if (this.props.plans && this.props.plans.length) {
      this.setBillingOptions();
    }
  };

  getPlanClicks = plan => {
    const { selectedBilling, billingOptions } = this.state;
    if (billingOptions.length && selectedBilling.id) {
      if (plan === 'Starter' && selectedBilling.interval === 'month') {
        const found = billingOptions.find(
          item =>
            item.plan.includes(plan) &&
            item.interval === 'month' &&
            item.interval_count === selectedBilling.interval_count
        );
        if (found) {
          return found.clicks;
        }
      } else if (plan === 'Starter' && selectedBilling.interval === 'year') {
        const found = billingOptions.find(
          item => item.plan.includes(plan) && item.interval === 'year'
        );
        if (found) {
          return found.clicks;
        }
      } else if (plan === 'Pro') {
        return this.state.proClicks;
      }
    }
    return '';
  };

  componentDidUpdate = preProps => {
    if (this.props.plans.length && !preProps.plans.length && !this.state.billingOptions.length) {
      this.setBillingOptions();
    }
    if (this.props.currentPlan && !preProps.currentPlan) {
      this.setBillingOptions();
    }
    if (!this.props.currentPlan && preProps.currentPlan) {
      this.setBillingOptions();
    }
  };

  updateClicks = val => {
    const { billingOptions, selectedBilling = {} } = this.state;
    let clicks = val;
    if (clicks > 10000 && clicks <= 20000) {
      clicks = 25000;
    } else if (clicks > 20000 && clicks <= 30000) {
      clicks = 50000;
    } else if (clicks > 30000 && clicks <= 40000) {
      clicks = 75000;
    } else if (clicks > 40000) {
      clicks = 100000;
    }
    this.setState({
      proClicks: clicks,
      selectedBilling: billingOptions.find(
        item =>
          item.plan === 'Pro' &&
          item.clicks === clicks &&
          item.interval === selectedBilling.interval &&
          item.interval_count === selectedBilling.interval_count
      )
    });
  };

  changePlan = (plan, doSave = false) => {
    this.setState({ selectedBilling: plan }, () => {
      if (doSave) {
        this.props.toggleConfirmPlanModal(plan);
      }
    });
  };

  getPrice = price => {
    const { currentPlan, isCancelable, discount, currentDiscount } = this.props;
    const { selectedBilling, proClicks } = this.state;
    if (
      currentDiscount &&
      ((currentPlan &&
        currentPlan.nickname.includes('Starter') &&
        currentPlan.interval_count === selectedBilling.interval_count &&
        currentPlan.interval === selectedBilling.interval) ||
        (currentPlan &&
          currentPlan.nickname.includes('Pro') &&
          currentPlan.interval === selectedBilling.interval &&
          currentPlan.interval_count === selectedBilling.interval_count &&
          Number(currentPlan.metadata.clicks) === proClicks))
    ) {
      return price - currentDiscount;
    }
    if (
      !(
        (!(
          currentPlan &&
          currentPlan.nickname.includes('Starter') &&
          currentPlan.interval === selectedBilling.interval &&
          currentPlan.interval_count === selectedBilling.interval_count
        ) ||
          !isCancelable) &&
        (!currentPlan || currentPlan.nickname.includes('Starter'))
      ) &&
      !(
        !(
          currentPlan &&
          currentPlan.nickname.includes('Pro') &&
          currentPlan.interval_count === selectedBilling.interval_count &&
          currentPlan.interval === selectedBilling.interval &&
          Number(currentPlan.metadata.clicks) === proClicks
        ) || !isCancelable
      )
    ) {
      return price;
    }
    if (currentDiscount && !discount) {
      return price - currentDiscount;
    }
    if (!discount) {
      return price;
    }
    return price - (price * discount) / 100;
  };

  getPriceDifference = () => {
    const { selectedBilling, proClicks, billingOptions } = this.state;
    const { currency, conversionRates } = this.props;
    return Utils.convertToCurrency(
      conversionRates,
      this.getPrice(
        billingOptions.find(
          item =>
            item.plan === 'Pro' &&
            item.interval === selectedBilling.interval &&
            item.interval_count === selectedBilling.interval_count &&
            item.clicks === proClicks
        ).price
      ) -
        billingOptions.find(
          item =>
            item.plan === 'Starter' &&
            item.interval === selectedBilling.interval &&
            item.interval_count === selectedBilling.interval_count
        ).price,
      currency
    );
  };

  render() {
    const {
      isOpen,
      compareOnly,
      currentPlan,
      toggleCancelPlan,
      isCancelable,
      conversionRates,
      currency,
      discount
    } = this.props;
    const { selectedBilling, proClicks, billingOptions = [] } = this.state;
    return (
      <>
        <Modal
          isOpen={isOpen}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Compare Plans"
        >
          <div className={styles.container}>
            <span className={styles.closeBtn} onClick={this.handleCloseModal} aria-hidden="true">
              ×
            </span>

            <div className={`${styles.content} ${styles.compareModalContent}`}>
              <p className={styles.headerText}>
                {compareOnly ? 'Compare Plans' : 'Adjust Your Plan'}
              </p>
              {billingOptions.length > 0 && selectedBilling.id ? (
                <>
                  <div className={styles.switchDuration}>
                    <div className={styles.switchButtons}>
                      <div
                        onClick={() =>
                          this.changePlan(
                            billingOptions &&
                              billingOptions.find(
                                item =>
                                  item.plan === selectedBilling.plan &&
                                  (selectedBilling.plan === 'Starter' ||
                                    item.clicks === proClicks) &&
                                  item.interval === 'year'
                              )
                          )
                        }
                        className={selectedBilling.interval === 'year' ? styles.activeState : ''}
                      >
                        Annual<span>Save 20%</span>
                      </div>
                      <div
                        onClick={() =>
                          this.changePlan(
                            billingOptions &&
                              billingOptions.find(
                                item =>
                                  item.plan === selectedBilling.plan &&
                                  (selectedBilling.plan === 'Starter' ||
                                    item.clicks === proClicks) &&
                                  item.interval === 'month' &&
                                  item.interval_count === 3
                              )
                          )
                        }
                        className={
                          selectedBilling.interval_count === 3 &&
                          selectedBilling.interval === 'month'
                            ? styles.activeState
                            : ''
                        }
                      >
                        Quarterly<span>Save 12%</span>
                      </div>
                      <div
                        className={
                          selectedBilling.interval_count === 1 &&
                          selectedBilling.interval === 'month'
                            ? styles.activeState
                            : ''
                        }
                        onClick={() =>
                          this.changePlan(
                            billingOptions &&
                              billingOptions.find(
                                item =>
                                  item.plan === selectedBilling.plan &&
                                  (selectedBilling.plan === 'Starter' ||
                                    item.clicks === proClicks) &&
                                  item.interval === 'month' &&
                                  item.interval_count === 1
                              )
                          )
                        }
                      >
                        Monthly
                      </div>
                    </div>
                  </div>
                  <div className={styles.gridContent}>
                    <div className={styles.gridCell}>
                      <div className={styles.planHeading}>Starter</div>
                      {/* <div className={styles.planSubHeading}>7-day free trial</div> */}
                      <div className={styles.planValue} style={{ marginBottom: '38px' }}>
                        {currencySymbols[currency] || '$'}
                        {billingOptions.length &&
                          selectedBilling.id &&
                          Utils.convertToCurrency(
                            conversionRates,
                            billingOptions.find(
                              item =>
                                item.plan === 'Starter' &&
                                item.interval === selectedBilling.interval &&
                                item.interval_count === selectedBilling.interval_count
                            ).price,
                            currency
                          ).toLocaleString('en-US')}{' '}
                        <span>
                          {' '}
                          /{' '}
                          {selectedBilling.interval_count === 3
                            ? 'quarter'
                            : selectedBilling.interval}
                        </span>
                      </div>
                      <div className={styles.planAdv}>
                        <div className={styles.clicksDigit}>
                          Up to{' '}
                          {this.getPlanClicks('Starter').toLocaleString('en-US', {
                            maximumFractionDigits: 1
                          })}{' '}
                          Ad Clicks
                        </div>
                        {!compareOnly &&
                          (!(
                            currentPlan &&
                            currentPlan.nickname.includes('Starter') &&
                            currentPlan.interval === selectedBilling.interval &&
                            currentPlan.interval_count === selectedBilling.interval_count
                          ) ||
                            !isCancelable) &&
                          (!currentPlan || currentPlan.nickname.includes('Starter') ? (
                            <div className={styles.btnOuter}>
                              <Button
                                onClick={() =>
                                  this.changePlan(
                                    billingOptions.find(
                                      option =>
                                        option.plan === 'Starter' &&
                                        option.interval === selectedBilling.interval &&
                                        option.interval_count === selectedBilling.interval_count
                                    ),
                                    true
                                  )
                                }
                                title="Switch"
                                color="lt-blue-auto"
                              />
                            </div>
                          ) : (
                            <a
                              className={styles.contactUs}
                              href="https://fraudblocker.com/contact-us"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Contact us to downgrade
                            </a>
                          ))}
                        {!compareOnly &&
                          isCancelable &&
                          currentPlan &&
                          currentPlan.nickname.includes('Starter') &&
                          currentPlan.interval === selectedBilling.interval &&
                          currentPlan.interval_count === selectedBilling.interval_count && (
                            <div className={styles.btnOuter}>
                              <Button
                                disabled
                                onClick={() => {}}
                                title="Current Plan"
                                color="lt-gray"
                              />
                            </div>
                          )}
                        <ul>
                          <li className={styles.active}>
                            {billingOptions.length &&
                              selectedBilling.id &&
                              billingOptions.find(
                                item =>
                                  item.plan === 'Starter' &&
                                  item.interval === selectedBilling.interval &&
                                  item.interval_count === selectedBilling.interval_count
                              ).domains}{' '}
                            Website
                          </li>
                          <li className={styles.greyColor}>Fraud Traffic Scoring</li>
                          <li className={styles.greyColor}>Real-Time, 24/7 Monitoring</li>
                          <li className={styles.greyColor}>Automated Blocking</li>
                          <li className={styles.greyColor}>Google & Meta Protection</li>
                          <li className={styles.inActive}>Dedicated Account Manager</li>
                        </ul>
                      </div>
                    </div>
                    <div className={`${styles.gridCell} ${styles.activeCell}`}>
                      <div
                        className={`${styles.bestValue} ${discount ? styles.discountApplied : ''}`}
                      >
                        {discount ? `${discount}% Discount Applied!` : 'Best Value'}
                      </div>
                      <div className={styles.planHeading}>Pro</div>
                      {/* <div className={styles.planSubHeading}>7-day free trial</div> */}
                      <div className={styles.planValue}>
                        {currencySymbols[currency] || '$'}
                        {billingOptions.length &&
                          selectedBilling &&
                          selectedBilling.id &&
                          Utils.convertToCurrency(
                            conversionRates,
                            this.getPrice(
                              billingOptions.find(
                                item =>
                                  item.plan === 'Pro' &&
                                  item.interval === selectedBilling.interval &&
                                  item.interval_count === selectedBilling.interval_count &&
                                  item.clicks === proClicks
                              ).price
                            ),
                            currency
                          ).toLocaleString('en-US')}{' '}
                        <span>
                          {' '}
                          /{' '}
                          {selectedBilling.interval_count === 3
                            ? 'quarter'
                            : selectedBilling.interval}
                        </span>
                      </div>
                      {this.getPriceDifference() > 0 && (
                        <div className={styles.planDiscount}>
                          or{' '}
                          <span>
                            {currencySymbols[currency] || '$'}
                            {this.getPriceDifference().toLocaleString('en-US')}
                          </span>{' '}
                          more a{' '}
                          {selectedBilling.interval_count === 3
                            ? 'quarter'
                            : selectedBilling.interval}
                        </div>
                      )}
                      <div className={styles.planAdv}>
                        <div className={styles.rangeSlider}>
                          <RangeSlider
                            markers={[10000, 20000, 30000, 40000, 50000]}
                            value={clicksValueMap[proClicks]}
                            handleChange={this.updateClicks}
                          />
                        </div>
                        <div className={styles.clicksDigit}>
                          Up to{' '}
                          <span>
                            {this.getPlanClicks('Pro').toLocaleString('en-US', {
                              maximumFractionDigits: 1
                            })}
                          </span>{' '}
                          Ad Clicks
                        </div>
                        {!compareOnly &&
                          (!(
                            currentPlan &&
                            currentPlan.nickname.includes('Pro') &&
                            currentPlan.interval === selectedBilling.interval &&
                            currentPlan.interval_count === selectedBilling.interval_count &&
                            Number(currentPlan.metadata.clicks) === proClicks
                          ) ||
                            !isCancelable) && (
                            <div className={styles.btnOuter}>
                              <Button
                                onClick={() =>
                                  this.changePlan(
                                    billingOptions.find(
                                      option =>
                                        option.plan === 'Pro' &&
                                        option.interval === selectedBilling.interval &&
                                        option.interval_count === selectedBilling.interval_count &&
                                        option.clicks === proClicks
                                    ),
                                    true
                                  )
                                }
                                title="Switch"
                                color="lt-blue-auto"
                              />
                            </div>
                          )}
                        {!compareOnly &&
                          isCancelable &&
                          currentPlan &&
                          currentPlan.nickname.includes('Pro') &&
                          currentPlan.interval === selectedBilling.interval &&
                          currentPlan.interval_count === selectedBilling.interval_count &&
                          Number(currentPlan.metadata.clicks) === proClicks && (
                            <div className={styles.btnOuter}>
                              <Button
                                disabled
                                onClick={() => {}}
                                title="Current Plan"
                                color="lt-gray"
                              />
                            </div>
                          )}
                        <ul>
                          {selectedBilling &&
                          selectedBilling.id &&
                          billingOptions.length &&
                          billingOptions.find(
                            item =>
                              item.plan === 'Pro' &&
                              item.interval === selectedBilling.interval &&
                              item.interval_count === selectedBilling.interval_count &&
                              item.clicks === proClicks
                          ).domains === 'unlimited' ? (
                            <li className={styles.active}>Unlimited Websites</li>
                          ) : (
                            <li className={styles.active}>
                              Up to{' '}
                              {selectedBilling &&
                                selectedBilling.id &&
                                billingOptions.length &&
                                billingOptions.find(
                                  item =>
                                    item.plan === 'Pro' &&
                                    item.interval === selectedBilling.interval &&
                                    item.interval_count === selectedBilling.interval_count &&
                                    item.clicks === proClicks
                                ).domains}{' '}
                              Websites
                            </li>
                          )}
                          <li className={styles.greyColor}>Fraud Traffic Scoring</li>
                          <li className={styles.greyColor}>Real-Time, 24/7 Monitoring</li>
                          <li className={styles.greyColor}>Automated Blocking</li>
                          <li className={styles.greyColor}>Google & Meta Protection</li>
                          <li className={styles.greyColor}>Dedicated Account Manager</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {!compareOnly && isCancelable && (
                    <div onClick={() => toggleCancelPlan()} className={styles.cancelAcc}>
                      <span>Cancel my plan</span>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

ChangePlanModal.propTypes = {
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  toggleModal: PropTypes.func,
  toggleCancelPlan: PropTypes.func,
  toggleConfirmPlanModal: PropTypes.func,
  plans: PropTypes.any,
  compareOnly: PropTypes.bool,
  isCancelable: PropTypes.bool,
  currentPlan: PropTypes.object,
  switchPlan: PropTypes.func,
  currency: PropTypes.string,
  conversionRates: PropTypes.any,
  discount: PropTypes.number,
  currentDiscount: PropTypes.number
};

export default ChangePlanModal;
