import momentTimezone from 'moment-timezone';
import moment from 'moment';
import USD_FLAG from '../assets/flags/us.svg';
import AUD_FLAG from '../assets/flags/au.svg';
import GBP_FLAG from '../assets/flags/gb.svg';
import CAN_FLAG from '../assets/flags/ca.svg';
import EUR_FLAG from '../assets/flags/er.svg';
import INR_FLAG from '../assets/flags/inr.svg';
import NZD_FLAG from '../assets/flags/nzd.svg';
import JPY_FLAG from '../assets/flags/jpy.svg';
import RUB_FLAG from '../assets/flags/rub.svg';
import CNY_FLAG from '../assets/flags/cny.svg';
import BRL_FLAG from '../assets/flags/brl.svg';
import HUF_FLAG from '../assets/flags/hu.svg';
import THB_FLAG from '../assets/flags/th.svg';
import SGD_FLAG from '../assets/flags/sg.svg';
import HKD_FLAG from '../assets/flags/hk.svg';
import ZAR_FLAG from '../assets/flags/zar.svg';
import TRY_FLAG from '../assets/flags/try.svg';
import AED_FLAG from '../assets/flags/aed.svg';
import MXN_FLAG from '../assets/flags/mxn.svg';
import CHF_FLAG from '../assets/flags/chf.svg';
import VND_FLAG from '../assets/flags/vnd.svg';

const stripePublicKey =
  'pk_live_51EOTMsIUwuoodzl9ORvIwaGbbjlfkcZMrHOuea5x3KvkjCjO0qIowiJJ7rXsYQk5F2SJ1Hu0zsaNWHbdqhY45Hvk00BVBakd5b';

const dropdownOptions = {
  values: [
    { value: 'Custom', label: 'Custom' },
    { value: 'Today', label: 'Today' },
    { value: 'Last 7 Days', label: 'Last 7 Days' },
    { value: 'Last 30 Days', label: 'Last 30 Days' },
    { value: 'This Month', label: 'This Month' },
    { value: 'Last 3 Months', label: 'Last 3 Months' }
    // { value: 'This Year', label: 'This Year' },
    // { value: 'Last Year', label: 'Last Year' },
    // { value: 'All Time', label: 'All Time' }
  ]
};

const stateOptions = {
  values: [
    {
      label: 'AL',
      value: 'AL'
    },
    {
      label: 'AK',
      value: 'AK'
    },
    {
      label: 'AS',
      value: 'AS'
    },
    {
      label: 'AZ',
      value: 'AZ'
    },
    {
      label: 'AR',
      value: 'AR'
    },
    {
      label: 'CA',
      value: 'CA'
    },
    {
      label: 'CO',
      value: 'CO'
    },
    {
      label: 'CT',
      value: 'CT'
    },
    {
      label: 'DE',
      value: 'DE'
    },
    {
      label: 'DC',
      value: 'DC'
    },
    {
      label: 'FM',
      value: 'FM'
    },
    {
      label: 'FL',
      value: 'FL'
    },
    {
      label: 'GA',
      value: 'GA'
    },
    {
      label: 'GU',
      value: 'GU'
    },
    {
      label: 'HI',
      value: 'HI'
    },
    {
      label: 'ID',
      value: 'ID'
    },
    {
      label: 'IL',
      value: 'IL'
    },
    {
      label: 'IN',
      value: 'IN'
    },
    {
      label: 'IA',
      value: 'IA'
    },
    {
      label: 'KS',
      value: 'KS'
    },
    {
      label: 'KY',
      value: 'KY'
    },
    {
      label: 'LA',
      value: 'LA'
    },
    {
      label: 'ME',
      value: 'ME'
    },
    {
      label: 'MH',
      value: 'MH'
    },
    {
      label: 'MD',
      value: 'MD'
    },
    {
      label: 'MA',
      value: 'MA'
    },
    {
      label: 'MI',
      value: 'MI'
    },
    {
      label: 'MN',
      value: 'MN'
    },
    {
      label: 'MS',
      value: 'MS'
    },
    {
      label: 'MO',
      value: 'MO'
    },
    {
      label: 'MT',
      value: 'MT'
    },
    {
      label: 'NE',
      value: 'NE'
    },
    {
      label: 'NV',
      value: 'NV'
    },
    {
      label: 'NH',
      value: 'NH'
    },
    {
      label: 'NJ',
      value: 'NJ'
    },
    {
      label: 'NM',
      value: 'NM'
    },
    {
      label: 'NY',
      value: 'NY'
    },
    {
      label: 'NC',
      value: 'NC'
    },
    {
      label: 'ND',
      value: 'ND'
    },
    {
      label: 'MP',
      value: 'MP'
    },
    {
      label: 'OH',
      value: 'OH'
    },
    {
      label: 'OK',
      value: 'OK'
    },
    {
      label: 'OR',
      value: 'OR'
    },
    {
      label: 'PW',
      value: 'PW'
    },
    {
      label: 'PA',
      value: 'PA'
    },
    {
      label: 'PR',
      value: 'PR'
    },
    {
      label: 'RI',
      value: 'RI'
    },
    {
      label: 'SC',
      value: 'SC'
    },
    {
      label: 'SD',
      value: 'SD'
    },
    {
      label: 'TN',
      value: 'TN'
    },
    {
      label: 'TX',
      value: 'TX'
    },
    {
      label: 'UT',
      value: 'UT'
    },
    {
      label: 'VT',
      value: 'VT'
    },
    {
      label: 'VI',
      value: 'VI'
    },
    {
      label: 'VA',
      value: 'VA'
    },
    {
      label: 'WA',
      value: 'WA'
    },
    {
      label: 'WV',
      value: 'WV'
    },
    {
      label: 'WI',
      value: 'WI'
    },
    {
      label: 'WY',
      value: 'WY'
    }
  ]
};

const validExtensions = [
  '.aaa',
  '.aarp',
  '.abarth',
  '.abb',
  '.abbott',
  '.abbvie',
  '.abc',
  '.abudhabi',
  '.ac',
  '.ac.cn',
  '.academy',
  '.accenture',
  '.accountant',
  '.accountants',
  '.aco',
  '.active',
  '.actor',
  '.ads',
  '.adult',
  '.ae',
  '.aeg',
  '.aero',
  '.aetna',
  '.af',
  '.afl',
  '.africa',
  '.ag',
  '.agakhan',
  '.agency',
  '.ah.cn',
  '.aig',
  '.aigo',
  '.airbus',
  '.airforce',
  '.airtel',
  '.akdn',
  '.alfaromeo',
  '.alibaba',
  '.alipay',
  '.allfinanz',
  '.allstate',
  '.ally',
  '.alsace',
  '.alstom',
  '.am',
  '.amazon',
  '.americanexpress',
  '.amex',
  '.amica',
  '.amsterdam',
  '.analytics',
  '.android',
  '.anz',
  '.aol',
  '.apartments',
  '.app',
  '.apple',
  '.aquarelle',
  '.ar',
  '.arab',
  '.aramco',
  '.archi',
  '.army',
  '.art',
  '.asia',
  '.asn.au',
  '.associates',
  '.at',
  '.attorney',
  '.auction',
  '.audi',
  '.audible',
  '.audio',
  '.auspost',
  '.author',
  '.auto',
  '.autos',
  '.aw',
  '.aws',
  '.ax',
  '.axa',
  '.azure',
  '.baby',
  '.baidu',
  '.bananarepublic',
  '.band',
  '.bank',
  '.bar',
  '.barcelona',
  '.barclaycard',
  '.barclays',
  '.barefoot',
  '.bargains',
  '.baseball',
  '.basketball',
  '.bauhaus',
  '.bayern',
  '.bbc',
  '.bbt',
  '.bbva',
  '.bcg',
  '.bcn',
  '.bd',
  '.be',
  '.beauty',
  '.beer',
  '.bentley',
  '.berlin',
  '.best',
  '.bestbuy',
  '.bet',
  '.bg',
  '.bharti',
  '.bible',
  '.bid',
  '.bike',
  '.bing',
  '.bingo',
  '.bio',
  '.biz',
  '.biz.pl',
  '.bj.cn',
  '.black',
  '.blackfriday',
  '.blanco',
  '.blockbuster',
  '.blog',
  '.bloomberg',
  '.blue',
  '.bms',
  '.bmw',
  '.bnl',
  '.bnpparibas',
  '.boehringer',
  '.bond',
  '.boo',
  '.book',
  '.booking',
  '.boots',
  '.bosch',
  '.bostik',
  '.boston',
  '.bot',
  '.boutique',
  '.box',
  '.bradesco',
  '.bridgestone',
  '.broadway',
  '.broker',
  '.brother',
  '.brussels',
  '.budapest',
  '.bugatti',
  '.build',
  '.builders',
  '.business',
  '.buy',
  '.buzz',
  '.bz',
  '.bzh',
  '.ca',
  '.cab',
  '.cafe',
  '.cal',
  '.call',
  '.calvinklein',
  '.cam',
  '.camera',
  '.camp',
  '.cancerresearch',
  '.canon',
  '.capetown',
  '.capital',
  '.capitalone',
  '.car',
  '.caravan',
  '.cards',
  '.care',
  '.career',
  '.careers',
  '.cars',
  '.cartier',
  '.case',
  '.cash',
  '.casino',
  '.cat',
  '.catering',
  '.catholic',
  '.cba',
  '.cbn',
  '.cbre',
  '.cbs',
  '.cc',
  '.center',
  '.ceo',
  '.cern',
  '.cfa',
  '.cfd',
  '.ch',
  '.chanel',
  '.channel',
  '.charity',
  '.chase',
  '.chat',
  '.cheap',
  '.chintai',
  '.christmas',
  '.chrome',
  '.chrysler',
  '.church',
  '.ci',
  '.cipriani',
  '.circle',
  '.cisco',
  '.citadel',
  '.citi',
  '.citic',
  '.city',
  '.cl',
  '.claims',
  '.cleaning',
  '.click',
  '.clinic',
  '.clothing',
  '.cloud',
  '.club',
  '.clubmed',
  '.cn',
  '.co',
  '.co.at',
  '.co.hu',
  '.co.id',
  '.co.il',
  '.co.in',
  '.co.ke',
  '.co.nz',
  '.co.th',
  '.co.uk',
  '.co.za',
  '.coach',
  '.codes',
  '.coffee',
  '.college',
  '.cologne',
  '.com',
  '.com.ar',
  '.com.au',
  '.com.br',
  '.com.cn',
  '.com.co',
  '.com.do',
  '.com.es',
  '.com.hk',
  '.com.hr',
  '.com.mx',
  '.com.ng',
  '.com.pe',
  '.com.pl',
  '.com.sg',
  '.com.tr',
  '.com.tw',
  '.com.ua',
  '.comcast',
  '.commbank',
  '.community',
  '.company',
  '.compare',
  '.computer',
  '.condos',
  '.construction',
  '.consulting',
  '.contact',
  '.contractors',
  '.cooking',
  '.cool',
  '.coop',
  '.corsica',
  '.country',
  '.coupon',
  '.coupons',
  '.courses',
  '.cpa',
  '.cq.cn',
  '.cr',
  '.credit',
  '.creditcard',
  '.creditunion',
  '.cricket',
  '.crown',
  '.crs',
  '.cruise',
  '.cruises',
  '.csc',
  '.cuisinella',
  '.cx',
  '.cymru',
  '.cyou',
  '.cz',
  '.dabur',
  '.dad',
  '.dance',
  '.data',
  '.date',
  '.dating',
  '.datsun',
  '.day',
  '.de',
  '.deal',
  '.dealer',
  '.deals',
  '.degree',
  '.delivery',
  '.dell',
  '.deloitte',
  '.delta',
  '.democrat',
  '.dental',
  '.dentist',
  '.design',
  '.dev',
  '.dhl',
  '.diamonds',
  '.diet',
  '.digital',
  '.direct',
  '.directory',
  '.discount',
  '.discover',
  '.dish',
  '.diy',
  '.dk',
  '.dnp',
  '.do',
  '.docs',
  '.doctor',
  '.dodge',
  '.dog',
  '.doha',
  '.domains',
  '.dot',
  '.download',
  '.drive',
  '.dubai',
  '.duck',
  '.dunlop',
  '.dupont',
  '.durban',
  '.dvag',
  '.earth',
  '.eat',
  '.ec',
  '.eco',
  '.edeka',
  '.edu',
  '.edu.co',
  '.edu.mx',
  '.edu.pl',
  '.education',
  '.ee',
  '.email',
  '.emerck',
  '.energy',
  '.engineer',
  '.engineering',
  '.enterprises',
  '.entertainment',
  '.epson',
  '.equipment',
  '.ericsson',
  '.erni',
  '.es',
  '.esq',
  '.estate',
  '.esurance',
  '.etisalat',
  '.eurovision',
  '.eus',
  '.events',
  '.everbank',
  '.exchange',
  '.expert',
  '.exposed',
  '.express',
  '.extraspace',
  '.fage',
  '.fail',
  '.fairwinds',
  '.faith',
  '.family',
  '.fan',
  '.fans',
  '.farm',
  '.farmers',
  '.fashion',
  '.fast',
  '.fedex',
  '.feedback',
  '.ferrari',
  '.ferrero',
  '.fi',
  '.fiat',
  '.fidelity',
  '.film',
  '.final',
  '.finance',
  '.financial',
  '.fire',
  '.firestone',
  '.firm.in',
  '.firmdale',
  '.fish',
  '.fishing',
  '.fit',
  '.fitness',
  '.fj.cn',
  '.flickr',
  '.flights',
  '.flir',
  '.florist',
  '.flowers',
  '.flsmidth',
  '.fly',
  '.fm',
  '.fo',
  '.foo',
  '.food',
  '.foodnetwork',
  '.football',
  '.ford',
  '.forex',
  '.forsale',
  '.forum',
  '.foundation',
  '.fox',
  '.fr',
  '.free',
  '.fresenius',
  '.frl',
  '.frogans',
  '.frontdoor',
  '.frontier',
  '.fujitsu',
  '.fujixerox',
  '.fun',
  '.fund',
  '.furniture',
  '.fyi',
  '.gal',
  '.gallery',
  '.gallo',
  '.gallup',
  '.game',
  '.games',
  '.gap',
  '.garden',
  '.gay',
  '.gbiz',
  '.gd',
  '.gd.cn',
  '.gdn',
  '.gea',
  '.gen.in',
  '.gent',
  '.genting',
  '.gg',
  '.gi',
  '.gift',
  '.gifts',
  '.gives',
  '.giving',
  '.gl',
  '.glass',
  '.gle',
  '.global',
  '.globo',
  '.gmail',
  '.gmo',
  '.gmx',
  '.godaddy',
  '.gold',
  '.goldpoint',
  '.golf',
  '.goodyear',
  '.goog',
  '.google',
  '.gop',
  '.gov',
  '.gov.cn',
  '.grainger',
  '.graphics',
  '.green',
  '.gripe',
  '.grocery',
  '.group',
  '.gs',
  '.gs.cn',
  '.guardian',
  '.gucci',
  '.guide',
  '.guitars',
  '.guru',
  '.gx.cn',
  '.gy',
  '.gz.cn',
  '.ha.cn',
  '.hair',
  '.hamburg',
  '.hangout',
  '.hb.cn',
  '.hbo',
  '.hdfc',
  '.hdfcbank',
  '.he.cn',
  '.health',
  '.healthcare',
  '.help',
  '.helsinki',
  '.here',
  '.hermes',
  '.hi.cn',
  '.hiphop',
  '.hisamitsu',
  '.hitachi',
  '.hiv',
  '.hk',
  '.hk.cn',
  '.hkt',
  '.hl.cn',
  '.hn',
  '.hn.cn',
  '.hockey',
  '.holdings',
  '.holiday',
  '.homegoods',
  '.homes',
  '.homesense',
  '.honda',
  '.honeywell',
  '.horse',
  '.hospital',
  '.host',
  '.hosting',
  '.hot',
  '.hotels',
  '.hotmail',
  '.house',
  '.how',
  '.hr',
  '.hsbc',
  '.ht',
  '.hu',
  '.hughes',
  '.hyatt',
  '.hyundai',
  '.ibm',
  '.ice',
  '.icu',
  '.id',
  '.id.au',
  '.ie',
  '.ieee',
  '.ifm',
  '.ikano',
  '.im',
  '.imdb',
  '.in',
  '.inc',
  '.ind.in',
  '.industries',
  '.infiniti',
  '.info',
  '.info.pl',
  '.ing',
  '.ink',
  '.institute',
  '.insurance',
  '.insure',
  '.int',
  '.intel',
  '.international',
  '.intuit',
  '.investments',
  '.io',
  '.ipiranga',
  '.ir',
  '.irish',
  '.is',
  '.iselect',
  '.ist',
  '.istanbul',
  '.it',
  '.itau',
  '.itv',
  '.iveco',
  '.jaguar',
  '.java',
  '.jcb',
  '.jcp',
  '.je',
  '.jeep',
  '.jewelry',
  '.jl.cn',
  '.jobs',
  '.joburg',
  '.joy',
  '.jpmorgan',
  '.js.cn',
  '.juniper',
  '.jx.cn',
  '.kddi',
  '.ke',
  '.kerryhotels',
  '.kerrylogistics',
  '.kerryproperties',
  '.kfh',
  '.kg',
  '.kia',
  '.kim',
  '.kinder',
  '.kindle',
  '.kitchen',
  '.kiwi',
  '.koeln',
  '.komatsu',
  '.kpmg',
  '.kr',
  '.krd',
  '.kred',
  '.kuokgroup',
  '.ky',
  '.kyoto',
  '.kz',
  '.la',
  '.lacaixa',
  '.ladbrokes',
  '.lamborghini',
  '.lancaster',
  '.lancia',
  '.lancome',
  '.land',
  '.landrover',
  '.lanxess',
  '.lasalle',
  '.lat',
  '.latrobe',
  '.law',
  '.lawyer',
  '.lc',
  '.lds',
  '.lease',
  '.leclerc',
  '.legal',
  '.lego',
  '.lexus',
  '.lgbt',
  '.li',
  '.liaison',
  '.lidl',
  '.life',
  '.lifeinsurance',
  '.lifestyle',
  '.lighting',
  '.like',
  '.lilly',
  '.limited',
  '.limo',
  '.lincoln',
  '.linde',
  '.link',
  '.lipsy',
  '.live',
  '.living',
  '.lixil',
  '.ln.cn',
  '.loan',
  '.loans',
  '.locker',
  '.locus',
  '.lol',
  '.london',
  '.lotte',
  '.lotto',
  '.love',
  '.lpl',
  '.lplfinancial',
  '.lt',
  '.ltd',
  '.ltd.uk',
  '.lu',
  '.lundbeck',
  '.lupin',
  '.luxury',
  '.lv',
  '.ly',
  '.ma',
  '.macys',
  '.madrid',
  '.maif',
  '.makeup',
  '.man',
  '.management',
  '.mango',
  '.map',
  '.market',
  '.marketing',
  '.markets',
  '.marriott',
  '.maserati',
  '.mattel',
  '.mba',
  '.mckinsey',
  '.md',
  '.me',
  '.me.uk',
  '.med',
  '.media',
  '.meet',
  '.melbourne',
  '.meme',
  '.memorial',
  '.men',
  '.menu',
  '.metlife',
  '.mg',
  '.miami',
  '.microsoft',
  '.mil',
  '.mini',
  '.mint',
  '.mit',
  '.mitsubishi',
  '.mk',
  '.mlb',
  '.mma',
  '.mn',
  '.mo.cn',
  '.mobi',
  '.mobile',
  '.mobily',
  '.moe',
  '.mom',
  '.monash',
  '.money',
  '.monster',
  '.mormon',
  '.mortgage',
  '.moscow',
  '.moto',
  '.motorcycles',
  '.mov',
  '.movie',
  '.movistar',
  '.ms',
  '.msd',
  '.mtn',
  '.mtr',
  '.mu',
  '.museum',
  '.music',
  '.mutual',
  '.mx',
  '.nadex',
  '.nagoya',
  '.name',
  '.nationwide',
  '.natura',
  '.navy',
  '.nba',
  '.nc',
  '.nec',
  '.net',
  '.net.au',
  '.net.cn',
  '.net.co',
  '.net.in',
  '.net.pl',
  '.net.uk',
  '.netflix',
  '.network',
  '.neustar',
  '.new',
  '.newholland',
  '.news',
  '.nexus',
  '.nf',
  '.nfl',
  '.ngo',
  '.nhk',
  '.nico',
  '.nike',
  '.nikon',
  '.ninja',
  '.nissan',
  '.nissay',
  '.nl',
  '.nm.cn',
  '.no',
  '.nokia',
  '.nom.co',
  '.nom.es',
  '.northwesternmutual',
  '.norton',
  '.now',
  '.nra',
  '.nrw',
  '.ntt',
  '.nu',
  '.nx.cn',
  '.nyc',
  '.nz',
  '.obi',
  '.observer',
  '.off',
  '.office',
  '.okinawa',
  '.om',
  '.omega',
  '.one',
  '.ong',
  '.onl',
  '.online',
  '.ooo',
  '.open',
  '.or.at',
  '.oracle',
  '.orange',
  '.org',
  '.org.au',
  '.org.cn',
  '.org.es',
  '.org.il',
  '.org.in',
  '.org.mx',
  '.org.pl',
  '.org.uk',
  '.organic',
  '.origins',
  '.osaka',
  '.otsuka',
  '.ovh',
  '.page',
  '.panasonic',
  '.paris',
  '.partners',
  '.parts',
  '.party',
  '.pay',
  '.pccw',
  '.pe',
  '.pet',
  '.pf',
  '.pfizer',
  '.pharmacy',
  '.philips',
  '.phone',
  '.photo',
  '.photography',
  '.photos',
  '.physio',
  '.piaget',
  '.pics',
  '.pictet',
  '.pictures',
  '.pid',
  '.pin',
  '.ping',
  '.pink',
  '.pioneer',
  '.pizza',
  '.pl',
  '.place',
  '.play',
  '.playstation',
  '.plumbing',
  '.plus',
  '.pm',
  '.pohl',
  '.poker',
  '.politie',
  '.porn',
  '.post',
  '.pr',
  '.praxi',
  '.press',
  '.prime',
  '.pro',
  '.prod',
  '.productions',
  '.prof',
  '.progressive',
  '.promo',
  '.properties',
  '.property',
  '.protection',
  '.pru',
  '.prudential',
  '.pt',
  '.pub',
  '.pw',
  '.pwc',
  '.qa',
  '.qh.cn',
  '.qpon',
  '.quebec',
  '.quest',
  '.qvc',
  '.racing',
  '.radio',
  '.re',
  '.read',
  '.realestate',
  '.realtor',
  '.realty',
  '.recipes',
  '.red',
  '.redstone',
  '.rehab',
  '.reit',
  '.reliance',
  '.rent',
  '.rentals',
  '.repair',
  '.report',
  '.republican',
  '.rest',
  '.restaurant',
  '.review',
  '.reviews',
  '.rexroth',
  '.rich',
  '.ricoh',
  '.rio',
  '.rip',
  '.rmit',
  '.ro',
  '.rocher',
  '.rocks',
  '.rodeo',
  '.rogers',
  '.room',
  '.rs',
  '.ru',
  '.rugby',
  '.ruhr',
  '.run',
  '.rw',
  '.rwe',
  '.ryukyu',
  '.saarland',
  '.safe',
  '.safety',
  '.sakura',
  '.sale',
  '.salon',
  '.samsung',
  '.sandvik',
  '.sandvikcoromant',
  '.sanofi',
  '.sap',
  '.save',
  '.saxo',
  '.sb',
  '.sbi',
  '.sbs',
  '.sc',
  '.sc.cn',
  '.sca',
  '.scb',
  '.schaeffler',
  '.schmidt',
  '.scholarships',
  '.school',
  '.schwarz',
  '.science',
  '.scjohnson',
  '.scor',
  '.scot',
  '.sd.cn',
  '.se',
  '.search',
  '.seat',
  '.secure',
  '.security',
  '.seek',
  '.select',
  '.sener',
  '.services',
  '.ses',
  '.seven',
  '.sew',
  '.sex',
  '.sexy',
  '.sfr',
  '.sg',
  '.sh',
  '.sh.cn',
  '.shangrila',
  '.sharp',
  '.shaw',
  '.shell',
  '.shoes',
  '.shop',
  '.shop.pl',
  '.shopping',
  '.show',
  '.showtime',
  '.shriram',
  '.si',
  '.silk',
  '.sina',
  '.singles',
  '.site',
  '.sk',
  '.ski',
  '.skin',
  '.sky',
  '.skype',
  '.sling',
  '.sm',
  '.smart',
  '.smile',
  '.sn',
  '.sn.cn',
  '.sncf',
  '.so',
  '.soccer',
  '.social',
  '.softbank',
  '.software',
  '.sohu',
  '.solar',
  '.solutions',
  '.song',
  '.sony',
  '.space',
  '.spiegel',
  '.sport',
  '.spot',
  '.spreadbetting',
  '.st',
  '.stada',
  '.staples',
  '.star',
  '.starhub',
  '.statebank',
  '.statefarm',
  '.statoil',
  '.stc',
  '.stcgroup',
  '.stockholm',
  '.storage',
  '.store',
  '.stream',
  '.studio',
  '.study',
  '.style',
  '.su',
  '.sucks',
  '.supplies',
  '.supply',
  '.support',
  '.surf',
  '.surgery',
  '.suzuki',
  '.swatch',
  '.swiftcover',
  '.swiss',
  '.sx',
  '.sx.cn',
  '.sydney',
  '.symantec',
  '.systems',
  '.taipei',
  '.talk',
  '.taobao',
  '.target',
  '.tatamotors',
  '.tatar',
  '.tattoo',
  '.tax',
  '.taxi',
  '.tc',
  '.tdk',
  '.team',
  '.tech',
  '.technology',
  '.tel',
  '.telecity',
  '.telefonica',
  '.temasek',
  '.tennis',
  '.teva',
  '.theater',
  '.theatre',
  '.tickets',
  '.tiffany',
  '.tips',
  '.tires',
  '.tirol',
  '.tj.cn',
  '.tjx',
  '.tl',
  '.tn',
  '.to',
  '.today',
  '.tokyo',
  '.tools',
  '.top',
  '.toray',
  '.toshiba',
  '.total',
  '.tours',
  '.town',
  '.toyota',
  '.toys',
  '.trade',
  '.trading',
  '.training',
  '.travel',
  '.travelchannel',
  '.travelers',
  '.travelersinsurance',
  '.trust',
  '.tube',
  '.tui',
  '.tunes',
  '.tv',
  '.tvs',
  '.tw',
  '.tw.cn',
  '.ubs',
  '.uconnect',
  '.ug',
  '.uk',
  '.unicom',
  '.university',
  '.uno',
  '.uol',
  '.ups',
  '.us',
  '.uy',
  '.vacations',
  '.vanguard',
  '.vc',
  '.vegas',
  '.ventures',
  '.verisign',
  '.vet',
  '.video',
  '.vig',
  '.viking',
  '.villas',
  '.vin',
  '.vip',
  '.virgin',
  '.visa',
  '.vision',
  '.vista',
  '.vistaprint',
  '.vivo',
  '.vlaanderen',
  '.vodka',
  '.volkswagen',
  '.volvo',
  '.vote',
  '.voting',
  '.voyage',
  '.wales',
  '.walmart',
  '.walter',
  '.wang',
  '.watch',
  '.watches',
  '.waw.pl',
  '.weather',
  '.weatherchannel',
  '.webcam',
  '.weber',
  '.website',
  '.wed',
  '.wedding',
  '.weir',
  '.wf',
  '.whoswho',
  '.wien',
  '.wiki',
  '.williamhill',
  '.win',
  '.windows',
  '.wine',
  '.winners',
  '.wme',
  '.wolterskluwer',
  '.woodside',
  '.work',
  '.works',
  '.world',
  '.wow',
  '.wtc',
  '.wtf',
  '.xbox',
  '.xerox',
  '.xfinity',
  '.xj.cn',
  '.xxx',
  '.xyz',
  '.xz.cn',
  '.yachts',
  '.yahoo',
  '.yamaxun',
  '.yandex',
  '.yn.cn',
  '.yodobashi',
  '.yoga',
  '.yokohama',
  '.you',
  '.youtube',
  '.zappos',
  '.zara',
  '.zero',
  '.zip',
  '.zippo',
  '.zj.cn',
  '.zone',
  '.zuerich'
];

const getAllTimezones = () => {
  const timeZones = momentTimezone.tz.names();
  const offsetTmz = [];

  timeZones.forEach((i, index) => {
    const formattedTimezone = `(GMT${moment.tz(timeZones[index]).format('Z')}) ${timeZones[index]}`;
    offsetTmz.push({ value: formattedTimezone, label: formattedTimezone });
  });

  return { values: offsetTmz.sort((a, b) => (a.value > b.value ? 1 : -1)) };
};

const roundAmount = (amount, decimal = 2) => {
  if (!amount) {
    return 0;
  }
  return (Math.round((amount + Number.EPSILON) * 100) / 100).toFixed(decimal);
};

const plansMapping = {
  'Pro - Annual': 'Pro (Annual) - 10k clicks',
  'Pro - Monthly': 'Pro (Monthly) - 10k clicks',
  'Starter - Annual': 'Starter (Annual) - 5k clicks',
  'Starter - Monthly': 'Starter (Monthly) - 5k clicks'
};

const subscriptionWarnings = {
  MISSING_CARD: 0,
  UPGRADE_PLAN: 1
};

const currencyOptions = [
  {
    label: 'USD',
    value: 'USD',
    icon: USD_FLAG
  },
  {
    label: 'GBP',
    value: 'GBP',
    icon: GBP_FLAG
  },
  {
    label: 'EUR',
    value: 'EUR',
    icon: EUR_FLAG
  },
  {
    label: 'AUD',
    value: 'AUD',
    icon: AUD_FLAG
  },
  {
    label: 'CAN',
    value: 'CAN',
    icon: CAN_FLAG
  },
  {
    label: 'INR',
    value: 'INR',
    icon: INR_FLAG
  },
  {
    label: 'NZD',
    value: 'NZD',
    icon: NZD_FLAG
  },
  {
    label: 'JPY',
    value: 'JPY',
    icon: JPY_FLAG
  },
  {
    label: 'RUB',
    value: 'RUB',
    icon: RUB_FLAG
  },
  {
    label: 'CNY',
    value: 'CNY',
    icon: CNY_FLAG
  },
  {
    label: 'BRL',
    value: 'BRL',
    icon: BRL_FLAG
  },
  {
    label: 'HUF',
    value: 'HUF',
    icon: HUF_FLAG
  },
  {
    label: 'THB',
    value: 'THB',
    icon: THB_FLAG
  },
  {
    label: 'SGD',
    value: 'SGD',
    icon: SGD_FLAG
  },
  {
    label: 'HKD',
    value: 'HKD',
    icon: HKD_FLAG
  },
  {
    label: 'ZAR',
    value: 'ZAR',
    icon: ZAR_FLAG
  },
  {
    label: 'TRY',
    value: 'TRY',
    icon: TRY_FLAG
  },
  {
    label: 'AED',
    value: 'AED',
    icon: AED_FLAG
  },
  {
    label: 'MXN',
    value: 'MXN',
    icon: MXN_FLAG
  },
  {
    label: 'CHF',
    value: 'CHF',
    icon: CHF_FLAG
  },
  {
    label: 'VND',
    value: 'VND',
    icon: VND_FLAG
  }
];

const currencySymbols = {
  USD: '$',
  CAN: '$',
  AUD: '$',
  GBP: '£',
  EUR: '€',
  INR: '₹',
  NZD: '$',
  JPY: '¥',
  RUB: '₽',
  CNY: '¥',
  BRL: 'R$',
  HUF: 'Ft',
  THB: '฿',
  SGD: 'S$',
  HKD: 'HK$',
  ZAR: 'R',
  TRY: '₺',
  AED: 'Dhs',
  MXN: 'MX$',
  CHF: 'CHF ',
  VND: '₫'
};

const countryNameMapping = {
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  WF: 'Wallis and Futuna',
  BL: 'Saint Barthelemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BT: 'Bhutan',
  JM: 'Jamaica',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  WS: 'Samoa',
  BQ: 'Bonaire, Saint Eustatius and Saba ',
  BR: 'Brazil',
  BS: 'Bahamas',
  JE: 'Jersey',
  BY: 'Belarus',
  BZ: 'Belize',
  RU: 'Russia',
  RW: 'Rwanda',
  RS: 'Serbia',
  TL: 'East Timor',
  RE: 'Reunion',
  TM: 'Turkmenistan',
  TJ: 'Tajikistan',
  RO: 'Romania',
  TK: 'Tokelau',
  GW: 'Guinea-Bissau',
  GU: 'Guam',
  GT: 'Guatemala',
  GS: 'South Georgia and the South Sandwich Islands',
  GR: 'Greece',
  GQ: 'Equatorial Guinea',
  GP: 'Guadeloupe',
  JP: 'Japan',
  GY: 'Guyana',
  GG: 'Guernsey',
  GF: 'French Guiana',
  GE: 'Georgia',
  GD: 'Grenada',
  GB: 'United Kingdom',
  GA: 'Gabon',
  SV: 'El Salvador',
  GN: 'Guinea',
  GM: 'Gambia',
  GL: 'Greenland',
  GI: 'Gibraltar',
  GH: 'Ghana',
  OM: 'Oman',
  TN: 'Tunisia',
  JO: 'Jordan',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HM: 'Heard Island and McDonald Islands',
  VE: 'Venezuela',
  PR: 'Puerto Rico',
  PS: 'Palestinian Territory',
  PW: 'Palau',
  PT: 'Portugal',
  SJ: 'Svalbard and Jan Mayen',
  PY: 'Paraguay',
  IQ: 'Iraq',
  PA: 'Panama',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PE: 'Peru',
  PK: 'Pakistan',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  ZM: 'Zambia',
  EH: 'Western Sahara',
  EE: 'Estonia',
  EG: 'Egypt',
  ZA: 'South Africa',
  EC: 'Ecuador',
  IT: 'Italy',
  VN: 'Vietnam',
  SB: 'Solomon Islands',
  ET: 'Ethiopia',
  SO: 'Somalia',
  ZW: 'Zimbabwe',
  SA: 'Saudi Arabia',
  ES: 'Spain',
  ER: 'Eritrea',
  ME: 'Montenegro',
  MD: 'Moldova',
  MG: 'Madagascar',
  MF: 'Saint Martin',
  MA: 'Morocco',
  MC: 'Monaco',
  UZ: 'Uzbekistan',
  MM: 'Myanmar',
  ML: 'Mali',
  MO: 'Macao',
  MN: 'Mongolia',
  MH: 'Marshall Islands',
  MK: 'Macedonia',
  MU: 'Mauritius',
  MT: 'Malta',
  MW: 'Malawi',
  MV: 'Maldives',
  MQ: 'Martinique',
  MP: 'Northern Mariana Islands',
  MS: 'Montserrat',
  MR: 'Mauritania',
  IM: 'Isle of Man',
  UG: 'Uganda',
  TZ: 'Tanzania',
  MY: 'Malaysia',
  MX: 'Mexico',
  IL: 'Israel',
  FR: 'France',
  IO: 'British Indian Ocean Territory',
  SH: 'Saint Helena',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NA: 'Namibia',
  VU: 'Vanuatu',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NZ: 'New Zealand',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  CK: 'Cook Islands',
  XK: 'Kosovo',
  CI: 'Ivory Coast',
  CH: 'Switzerland',
  CO: 'Colombia',
  CN: 'China',
  CM: 'Cameroon',
  CL: 'Chile',
  CC: 'Cocos Islands',
  CA: 'Canada',
  CG: 'Republic of the Congo',
  CF: 'Central African Republic',
  CD: 'Democratic Republic of the Congo',
  CZ: 'Czech Republic',
  CY: 'Cyprus',
  CX: 'Christmas Island',
  CR: 'Costa Rica',
  CW: 'Curacao',
  CV: 'Cape Verde',
  CU: 'Cuba',
  SZ: 'Swaziland',
  SY: 'Syria',
  SX: 'Sint Maarten',
  KG: 'Kyrgyzstan',
  KE: 'Kenya',
  SS: 'South Sudan',
  SR: 'Suriname',
  KI: 'Kiribati',
  KH: 'Cambodia',
  KN: 'Saint Kitts and Nevis',
  KM: 'Comoros',
  ST: 'Sao Tome and Principe',
  SK: 'Slovakia',
  KR: 'South Korea',
  SI: 'Slovenia',
  KP: 'North Korea',
  KW: 'Kuwait',
  SN: 'Senegal',
  SM: 'San Marino',
  SL: 'Sierra Leone',
  SC: 'Seychelles',
  KZ: 'Kazakhstan',
  KY: 'Cayman Islands',
  SG: 'Singapore',
  SE: 'Sweden',
  SD: 'Sudan',
  DO: 'Dominican Republic',
  DM: 'Dominica',
  DJ: 'Djibouti',
  DK: 'Denmark',
  VG: 'British Virgin Islands',
  DE: 'Germany',
  YE: 'Yemen',
  DZ: 'Algeria',
  US: 'United States',
  UY: 'Uruguay',
  YT: 'Mayotte',
  UM: 'United States Minor Outlying Islands',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LA: 'Laos',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TT: 'Trinidad and Tobago',
  TR: 'Turkey',
  LK: 'Sri Lanka',
  LI: 'Liechtenstein',
  LV: 'Latvia',
  TO: 'Tonga',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LR: 'Liberia',
  LS: 'Lesotho',
  TH: 'Thailand',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TD: 'Chad',
  TC: 'Turks and Caicos Islands',
  LY: 'Libya',
  VA: 'Vatican',
  VC: 'Saint Vincent and the Grenadines',
  AE: 'United Arab Emirates',
  AD: 'Andorra',
  AG: 'Antigua and Barbuda',
  AF: 'Afghanistan',
  AI: 'Anguilla',
  VI: 'U.S. Virgin Islands',
  IS: 'Iceland',
  IR: 'Iran',
  AM: 'Armenia',
  AL: 'Albania',
  AO: 'Angola',
  AQ: 'Antarctica',
  AS: 'American Samoa',
  AR: 'Argentina',
  AU: 'Australia',
  AT: 'Austria',
  AW: 'Aruba',
  IN: 'India',
  AX: 'Aland Islands',
  AZ: 'Azerbaijan',
  IE: 'Ireland',
  ID: 'Indonesia',
  UA: 'Ukraine',
  QA: 'Qatar',
  MZ: 'Mozambique'
};

const appSumoPlansPricing = {
  fraudblocker_tier5: 499,
  fraudblocker_tier4: 289,
  fraudblocker_tier3: 189,
  fraudblocker_tier2: 99,
  fraudblocker_tier1: 49
};

const countryTaxIdOptions = [
  { code: 'AD', country: 'Andorra', enum: 'ad_nrt', pattern: 'A-123456-Z' },
  { code: 'AR', country: 'Argentina', enum: 'ar_cuit', pattern: '12-3456789-01' },
  { code: 'AU', country: 'Australia', enum: 'au_abn', pattern: '12345678912' },
  { code: 'AU', country: 'Australia', enum: 'au_arn', pattern: '123456789123' },
  { code: 'AT', country: 'Austria', enum: 'eu_vat', pattern: 'ATU12345678' },
  { code: 'BE', country: 'Belgium', enum: 'eu_vat', pattern: 'BE0123456789' },
  { code: 'BO', country: 'Bolivia', enum: 'bo_tin', pattern: '123456789' },
  { code: 'BR', country: 'Brazil', enum: 'br_cnpj', pattern: '01.234.456/5432-10' },
  { code: 'BR', country: 'Brazil', enum: 'br_cpf', pattern: '123.456.789-87' },
  { code: 'BG', country: 'Bulgaria', enum: 'bg_uic', pattern: '123456789' },
  { code: 'BG', country: 'Bulgaria', enum: 'eu_vat', pattern: 'BG0123456789' },
  { code: 'CA', country: 'Canada', enum: 'ca_bn', pattern: '123456789' },
  { code: 'CA', country: 'Canada', enum: 'ca_gst_hst', pattern: '123456789RT0002' },
  { code: 'CA', country: 'Canada', enum: 'ca_pst_bc', pattern: 'PST-1234-5678' },
  { code: 'CA', country: 'Canada', enum: 'ca_pst_mb', pattern: '123456-7' },
  { code: 'CA', country: 'Canada', enum: 'ca_pst_sk', pattern: '1234567' },
  { code: 'CA', country: 'Canada', enum: 'ca_qst', pattern: '1234567890TQ1234' },
  { code: 'CL', country: 'Chile', enum: 'cl_tin', pattern: '12.345.678-K' },
  { code: 'CN', country: 'China', enum: 'cn_tin', pattern: '123456789012345678' },
  { code: 'CO', country: 'Colombia', enum: 'co_nit', pattern: '123.456.789-0' },
  { code: 'CR', country: 'Costa Rica', enum: 'cr_tin', pattern: '1-234-567890' },
  { code: 'HR', country: 'Croatia', enum: 'eu_vat', pattern: 'HR12345678912' },
  { code: 'CY', country: 'Cyprus', enum: 'eu_vat', pattern: 'CY12345678Z' },
  { code: 'CZ', country: 'Czech Republic', enum: 'eu_vat', pattern: 'CZ1234567890' },
  { code: 'DK', country: 'Denmark', enum: 'eu_vat', pattern: 'DK12345678' },
  { code: 'DO', country: 'Dominican Republic', enum: 'do_rcn', pattern: '123-4567890-1' },
  { code: 'EC', country: 'Ecuador', enum: 'ec_ruc', pattern: '1234567890001' },
  { code: 'EG', country: 'Egypt', enum: 'eg_tin', pattern: '123456789' },
  { code: 'SV', country: 'El Salvador', enum: 'sv_nit', pattern: '1234-567890-123-4' },
  { code: 'EE', country: 'Estonia', enum: 'eu_vat', pattern: 'EE123456789' },
  { code: 'EU', country: 'EU', enum: 'eu_oss_vat', pattern: 'EU123456789' },
  { code: 'FI', country: 'Finland', enum: 'eu_vat', pattern: 'FI12345678' },
  { code: 'FR', country: 'France', enum: 'eu_vat', pattern: 'FRAB123456789' },
  { code: 'GE', country: 'Georgia', enum: 'ge_vat', pattern: '123456789' },
  { code: 'DE', country: 'Germany', enum: 'eu_vat', pattern: 'DE123456789' },
  { code: 'GR', country: 'Greece', enum: 'eu_vat', pattern: 'EL123456789' },
  { code: 'HK', country: 'Hong Kong', enum: 'hk_br', pattern: '12345678' },
  { code: 'HU', country: 'Hungary', enum: 'eu_vat', pattern: 'HU12345678' },
  { code: 'HU', country: 'Hungary', enum: 'hu_tin', pattern: '12345678-1-23' },
  { code: 'IS', country: 'Iceland', enum: 'is_vat', pattern: '123456' },
  { code: 'IN', country: 'India', enum: 'in_gst', pattern: '12ABCDE3456FGZH' },
  { code: 'ID', country: 'Indonesia', enum: 'id_npwp', pattern: '12.345.678.9-012.345' },
  { code: 'IE', country: 'Ireland', enum: 'eu_vat', pattern: 'IE1234567AB' },
  { code: 'IL', country: 'Israel', enum: 'il_vat', pattern: '000012345' },
  { code: 'IT', country: 'Italy', enum: 'eu_vat', pattern: 'IT12345678912' },
  { code: 'JP', country: 'Japan', enum: 'jp_cn', pattern: '1234567891234' },
  { code: 'JP', country: 'Japan', enum: 'jp_rn', pattern: '12345' },
  { code: 'JP', country: 'Japan', enum: 'jp_trn', pattern: 'T1234567891234' },
  {
    code: 'KE',
    country: 'Kenya',
    enum: 'ke_pin',
    pattern: 'P000111111A'
  },
  { code: 'LV', country: 'Latvia', enum: 'eu_vat', pattern: 'LV12345678912' },
  { code: 'LI', country: 'Liechtenstein', enum: 'li_uid', pattern: 'CHE123456789' },
  { code: 'LT', country: 'Lithuania', enum: 'eu_vat', pattern: 'LT123456789123' },
  { code: 'LU', country: 'Luxembourg', enum: 'eu_vat', pattern: 'LU12345678' },
  { code: 'MY', country: 'Malaysia', enum: 'my_frp', pattern: '12345678' },
  { code: 'MY', country: 'Malaysia', enum: 'my_itn', pattern: '1234567890' },
  { code: 'MY', country: 'Malaysia', enum: 'my_sst', pattern: 'A12-3456-78912345' },
  { code: 'MT', country: 'Malta', enum: 'eu_vat', pattern: 'MT12345678' },
  { code: 'MX', country: 'Mexico', enum: 'mx_rfc', pattern: 'ABC010203AB9' },
  { code: 'NL', country: 'Netherlands', enum: 'eu_vat', pattern: 'NL123456789B12' },
  { code: 'NZ', country: 'New Zealand', enum: 'nz_gst', pattern: '123456789' },
  { code: 'NO', country: 'Norway', enum: 'no_vat', pattern: '123456789MVA' },
  { code: 'PE', country: 'Peru', enum: 'pe_ruc', pattern: '12345678901' },
  {
    code: 'PH',
    country: 'Philippines',
    enum: 'ph_tin',
    pattern: '123456789012'
  },
  { code: 'PL', country: 'Poland', enum: 'eu_vat', pattern: 'PL1234567890' },
  { code: 'PT', country: 'Portugal', enum: 'eu_vat', pattern: 'PT123456789' },
  { code: 'RO', country: 'Romania', enum: 'eu_vat', pattern: 'RO1234567891' },
  { code: 'RO', country: 'Romania', enum: 'ro_ti', pattern: '1234567890123' },
  { code: 'RU', country: 'Russia', enum: 'ru_inn', pattern: '1234567891' },
  { code: 'RU', country: 'Russia', enum: 'ru_kpp', pattern: '123456789' },
  { code: 'SA', country: 'Saudi Arabia', enum: 'sa_vat', pattern: '123456789012345' },
  { code: 'RS', country: 'Serbia', enum: 'rs_pib', pattern: '123456789' },
  { code: 'SG', country: 'Singapore', enum: 'sg_gst', pattern: 'M12345678X' },
  { code: 'SG', country: 'Singapore', enum: 'sg_uen', pattern: '123456789F' },
  { code: 'SK', country: 'Slovakia', enum: 'eu_vat', pattern: 'SK1234567891' },
  { code: 'SI', country: 'Slovenia', enum: 'eu_vat', pattern: 'SI12345678' },
  { code: 'SI', country: 'Slovenia', enum: 'si_tin', pattern: '12345678' },
  { code: 'ZA', country: 'South Africa', enum: 'za_vat', pattern: '4123456789' },
  { code: 'KR', country: 'South Korea', enum: 'kr_brn', pattern: '123-45-67890' },
  {
    code: 'ES',
    country: 'Spain',
    enum: 'es_cif',
    pattern: 'A12345678'
  },
  { code: 'ES', country: 'Spain', enum: 'eu_vat', pattern: 'ESA1234567Z' },
  { code: 'SE', country: 'Sweden', enum: 'eu_vat', pattern: 'SE123456789123' },
  { code: 'CH', country: 'Switzerland', enum: 'ch_vat', pattern: 'CHE-123.456.789 MWST' },
  { code: 'TW', country: 'Taiwan', enum: 'tw_vat', pattern: '12345678' },
  { code: 'TH', country: 'Thailand', enum: 'th_vat', pattern: '1234567891234' },
  { code: 'TR', country: 'Turkey', enum: 'tr_tin', pattern: '0123456789' },
  { code: 'UA', country: 'Ukraine', enum: 'ua_vat', pattern: '123456789' },
  {
    code: 'AE',
    country: 'United Arab Emirates',
    enum: 'ae_trn',
    pattern: '123456789012345'
  },
  { code: 'GB', country: 'United Kingdom', enum: 'eu_vat', pattern: 'XI123456789' },
  { code: 'GB', country: 'United Kingdom', enum: 'gb_vat', pattern: 'GB123456789' },
  { code: 'US', country: 'United States', enum: 'us_ein', pattern: '12-3456789' },
  { code: 'UY', country: 'Uruguay', enum: 'uy_ruc', pattern: '123456789012' },
  { code: 'VE', country: 'Venezuela', enum: 've_rif', pattern: 'A-12345678-9' },
  { code: 'VN', country: 'Vietnam', enum: 'vn_tin', pattern: '1234567890' }
];

const clicksValueMap = {
  '10000': 10000,
  '25000': 20000,
  '50000': 30000,
  '75000': 40000,
  '100000': 50000
};

export default {
  clicksValueMap,
  dropdownOptions,
  stateOptions,
  getAllTimezones,
  roundAmount,
  validExtensions,
  plansMapping,
  subscriptionWarnings,
  currencyOptions,
  currencySymbols,
  stripePublicKey,
  countryNameMapping,
  appSumoPlansPricing,
  countryTaxIdOptions
};
