import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../components/Button/Button';
import styles from './ConfirmPlanModal.module.scss';
import Constants from '../../utils/Constants';
import Utils from '../../utils/Utils';

const { currencySymbols } = Constants;

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    overflow: 'auto',
    padding: '40px 0'
  },
  content: {
    top: 20,
    right: 0,
    left: 0,
    bottom: 0,
    width: 577,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    padding: '40px 75px',
    position: 'relative'
  }
};

class ConfirmPlanModal extends PureComponent {
  handleCloseModal = () => {
    const { toggleModal } = this.props;
    toggleModal();
  };

  proceed = () => {
    const { selectedPlan, switchPlan, currentPlan } = this.props;
    const upgradeOrDowngrade =
      selectedPlan.price - currentPlan.amount > 0 ||
      selectedPlan.plan.toLowerCase().includes('boost')
        ? 1
        : -1;
    switchPlan(selectedPlan, upgradeOrDowngrade);
  };

  getPrice = price => {
    const { discount, currentDiscount } = this.props;
    if (!discount && currentDiscount) {
      return price - currentDiscount;
    }
    if (!discount) {
      return price;
    }
    return price - (price * discount) / 100;
  };

  getCurrentPlanPrice = price => {
    const { currentDiscount } = this.props;
    if (currentDiscount) {
      return price - currentDiscount;
    }
    return price;
  };

  render() {
    const {
      isOpen = [],
      currentPlan,
      selectedPlan,
      switching,
      conversionRates,
      currency,
      renewDate,
      source,
      plans
    } = this.props;
    return (
      <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false} contentLabel="Compare Plans">
        {currentPlan && selectedPlan && (
          <div className={styles.container}>
            <span className={styles.closeBtn} onClick={this.handleCloseModal} aria-hidden="true">
              ×
            </span>
            <div className={styles.content}>
              <p className={styles.headerText}>Confirm Your Plan</p>
              <div className={styles.gridContent}>
                <div className={styles.comparePrice}>
                  <div className={styles.priceCol}>
                    <div className={styles.colLabel}>Current Plan</div>
                    <div className={styles.planLabel}>Plan</div>
                    <div className={styles.planValue}>{currentPlan.nickname} Plan</div>
                    <div className={styles.planLabel}>Cost</div>
                    <div className={styles.planValue}>
                      {currencySymbols[currency] || '$'}
                      {Utils.convertToCurrency(
                        conversionRates,
                        this.getCurrentPlanPrice(currentPlan.amount),
                        currency
                      ).toLocaleString('en-US')}
                      /
                      {currentPlan.nickname.toLowerCase().includes('appsumo tier')
                        ? 'Lifetime'
                        : currentPlan.interval_count === 3
                        ? 'quarter'
                        : currentPlan.interval}
                    </div>
                    <div className={styles.planLabel}>Ad Clicks</div>
                    <div className={styles.planValue}>
                      {plans.length &&
                        plans
                          .find(item => item.id === currentPlan.id)
                          .clicks.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                    </div>
                    <div className={styles.planLabel}>Websites</div>
                    <div className={`${styles.planValue} ${styles.lastPlanValue}`}>
                      {plans.length &&
                      plans.find(item => item.id === currentPlan.id).domains === 'unlimited'
                        ? 'Unlimited'
                        : plans.find(item => item.id === currentPlan.id).domains}
                    </div>
                  </div>
                  <div className={styles.priceCol}>
                    <div className={styles.colLabel}>New Plan</div>
                    <div className={styles.planLabel}>Plan</div>
                    <div className={styles.planValue}>
                      {selectedPlan.nickname || selectedPlan.plan} Plan
                    </div>
                    <div className={styles.planLabel}>Cost</div>
                    <div className={styles.planValue}>
                      {currencySymbols[currency] || '$'}
                      {Utils.convertToCurrency(
                        conversionRates,
                        this.getPrice(selectedPlan.price),
                        currency
                      ).toLocaleString('en-US')}
                      /{selectedPlan.interval_count === 3 ? 'quarter' : selectedPlan.interval}
                    </div>
                    <div className={styles.planLabel}>Ad Clicks</div>
                    <div className={styles.planValue}>
                      +{selectedPlan.clicks.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                    </div>
                    <div className={styles.planLabel}>Websites</div>
                    <div className={`${styles.planValue} ${styles.lastPlanValue}`}>
                      {selectedPlan.domains !== 'unlimited' ? selectedPlan.domains : 'Unlimited'}
                    </div>
                  </div>
                </div>
                <div className={styles.priceAdust}>
                  <div className={styles.planLabel}>Total Adjustment Today</div>
                  {this.getCurrentPlanPrice(currentPlan.amount) <
                    this.getPrice(selectedPlan.price) ||
                  (selectedPlan.plan.toLowerCase().includes('boost') &&
                    currentPlan.nickname.toLowerCase().includes('appsumo tier')) ? (
                    <div
                      className={styles.planValue}
                      style={{
                        marginBottom: currentPlan.nickname.toLowerCase().includes('appsumo')
                          ? '38px'
                          : '16px'
                      }}
                    >
                      +{currencySymbols[currency] || '$'}
                      {Utils.convertToCurrency(
                        conversionRates,
                        this.getPrice(selectedPlan.price) -
                          (selectedPlan.plan.toLowerCase().includes('boost') &&
                          currentPlan.nickname.toLowerCase().includes('appsumo tier')
                            ? 0
                            : this.getCurrentPlanPrice(currentPlan.amount)),
                        currency
                      ).toLocaleString('en-US')}
                      /{selectedPlan.interval_count === 3 ? 'quarter' : selectedPlan.interval}
                    </div>
                  ) : (
                    <div className={styles.planValue}>{currencySymbols[currency] || '$'}0</div>
                  )}
                  <div className={styles.planLabel}>Next Billing Amount</div>
                  <div className={`${styles.planValue} ${styles.planPriceSec}`}>
                    {currencySymbols[currency] || '$'}
                    {Utils.convertToCurrency(
                      conversionRates,
                      this.getPrice(selectedPlan.price),
                      currency
                    ).toLocaleString('en-US')}
                  </div>
                  <div className={styles.planLabel}>Next Billing Date</div>
                  <div className={`${styles.planValue} ${styles.lastPlanValue}`}>{renewDate}</div>
                </div>
              </div>
              {(!selectedPlan || !selectedPlan.plan.toLowerCase().includes('boost') || source) && (
                <div className={styles.planState}>
                  {this.getCurrentPlanPrice(currentPlan.amount) < this.getPrice(selectedPlan.price)
                    ? 'By selecting this plan your credit card will be charged a pro-rated amount of your “Total Adjustment” based on the remaining days until your “Next Billing Date.” If you are still in your free trial period, your card will only be charged once the trial ends.'
                    : 'By selecting this plan you agree to the adjustment of your "Billing Amount". Your current plan will remain active until the "Next Billing Date".'}
                </div>
              )}
              <div className={styles.btnWrapper}>
                <Button
                  disabled={switching}
                  loading={switching}
                  onClick={this.proceed}
                  title={
                    selectedPlan && selectedPlan.plan.toLowerCase().includes('boost') && !source
                      ? 'Continue'
                      : 'Confirm Updates To Plan'
                  }
                  color={
                    selectedPlan && selectedPlan.plan.toLowerCase().includes('boost') && !source
                      ? 'lt-blue'
                      : 'lt-green'
                  }
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

ConfirmPlanModal.propTypes = {
  isOpen: PropTypes.bool,
  switching: PropTypes.bool,
  toggleModal: PropTypes.func,
  currentPlan: PropTypes.object,
  selectedPlan: PropTypes.object,
  switchPlan: PropTypes.func,
  currency: PropTypes.string,
  conversionRates: PropTypes.any,
  renewDate: PropTypes.string,
  plans: PropTypes.array,
  source: PropTypes.any,
  discount: PropTypes.number,
  currentDiscount: PropTypes.number
};

export default ConfirmPlanModal;
