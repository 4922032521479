import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './RegisterForms.module.scss';
import Button from '../../../components/Button/Button';
import Payments from '../../../api/Payments';
import Constants from '../../../utils/Constants';
import Dropdown from '../../../components/Dropdown/Dropdown';
import RangeSlider from '../../../components/RangeSlider/RangeSlider';
import PaypalGrey from '../../../assets/paypal-grey.svg';
import CardGreen from '../../../assets/creditcard-green.svg';
import CouponTick from '../../../assets/coupon-tick.svg';
import Utils from '../../../utils/Utils';
import ErrorBox from '../../../components/ErrorBox/ErrorBox';

const customStyles = {
  input: {
    marginBottom: 25
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: '600',
    color: '#666666'
  },
  comingSoon: {
    paddingTop: '10px',
    marginBottom: '-10px',
    fontWeight: 500,
    fontSize: '10px'
  },
  disabledRadio: {
    background: '#f1f1f1',
    border: 'none',
    pointerEvents: 'none'
  }
};

const { currencyOptions, currencySymbols } = Constants;

const planOptions = ['Pro', 'Starter'];

class AccountCreationForm extends PureComponent {
  constructor(props) {
    super(props);

    const { user = {}, accounts } = props;
    const { data = {} } = accounts;
    this.state = {
      firstName: user.first_name || '',
      lastName: user.last_name || '',
      city: data.billing_city || '',
      state: data.billing_state ? { value: data.billing_state, label: data.billing_state } : '',
      zip: data.billing_zip || '',
      errors: {},
      loading: false,
      formHasBeenSubmitted: false,
      currency: currencyOptions[0],
      selectedPlan: '',
      selectedBilling: {},
      billingOptions: [],
      paymentMethod: 'CreditCard',
      allPlans: [],
      proClicks: 10000
    };
  }

  componentWillUnmount() {
    const { formHasBeenSubmitted } = this.state;
    /**
     * If user leaves form without hitting next,
     * update User with every non empty field in DB
     */
    if (!formHasBeenSubmitted) {
      // this.updateUserWithNonEmptyFields();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    console.log(window.gtag);
    this.fetchAllPlans();
    if (this.props.currency) {
      this.setState({
        currency: currencyOptions.find(item => item.value === this.props.currency)
      });
    }
    if (window.gtag) {
      const script = document.createElement('script');
      const inlineScript = document.createTextNode(
        `gtag('event', 'conversion', {'send_to': 'AW-743398152/qC2mCIn-wa0DEIi2veIC'});`
      );
      script.appendChild(inlineScript);
      document.head.appendChild(script);
    }
  }

  fetchAllPlans = async () => {
    try {
      const { accounts } = this.props;
      const result = await Payments.getAllPlans();
      const billingOptions = [];
      result.data.forEach(plan => {
        const option = plan.nickname.split(' - ')[0];
        const billingOption = {
          id: plan.id,
          plan: option,
          nickname: plan.nickname,
          trial_period_days: plan.trial_period_days || 30,
          interval: plan.interval,
          interval_count: plan.interval_count,
          price: plan.amount / 100,
          clicks: parseInt(plan.metadata.clicks, 10),
          domains: plan.metadata.domains
        };
        billingOptions.push(billingOption);
      });

      if (result) {
        const defaultValues = {
          proClicks: billingOptions.find(
            item =>
              item.plan === planOptions[0] && item.interval === 'month' && item.interval_count === 1
          ).clicks,
          selectedPlan: planOptions[1],
          selectedBilling: billingOptions.find(
            item =>
              item.plan === planOptions[1] && item.interval === 'month' && item.interval_count === 1
          )
        };
        if (
          !billingOptions.find(
            item =>
              item.plan === planOptions[0] &&
              item.interval === 'year' &&
              item.clicks === defaultValues.proClicks
          )
        ) {
          defaultValues.proClicks = 10000;
        }
        this.setState({
          allPlans: result.data,
          selectedPlan:
            accounts.data && accounts.data.initial_plan
              ? accounts.data.initial_plan.plan || defaultValues.selectedPlan
              : defaultValues.selectedPlan,
          selectedBilling:
            accounts.data && accounts.data.initial_plan
              ? billingOptions.find(
                  item =>
                    item.plan === accounts.data.initial_plan.plan &&
                    item.interval === accounts.data.initial_plan.interval &&
                    item.interval_count === accounts.data.initial_plan.interval_count &&
                    item.clicks === accounts.data.initial_plan.clicks
                ) || defaultValues.selectedBilling
              : defaultValues.selectedBilling, // could prioritize auto selecting defaults here by adding to comparisons.
          billingOptions,
          proClicks:
            accounts.data && accounts.data.initial_plan && accounts.data.initial_plan.plan === 'Pro'
              ? accounts.data.initial_plan.clicks || defaultValues.proClicks
              : defaultValues.proClicks
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onSelectOption = (type, value) => {
    this.setState({ [type]: value });
  };

  handleChange = change => {
    // console.log('[change]', change);
    if (change.complete) {
      this.setState({ [change.elementType]: true });
    }
  };

  onChangeText = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  changePlan = plan => {
    this.setState({
      selectedPlan: plan.plan,
      selectedBilling: plan
    });
  };

  getPlanClicks = plan => {
    const { selectedBilling = {}, billingOptions } = this.state;
    if (billingOptions.length && selectedBilling.id) {
      if (plan === 'Starter' && selectedBilling.interval === 'month') {
        const found = billingOptions.find(
          item =>
            item.plan.includes(plan) &&
            item.interval === 'month' &&
            item.interval_count === selectedBilling.interval_count
        );
        if (found) {
          return found.clicks;
        }
      } else if (plan === 'Starter' && selectedBilling.interval === 'year') {
        const found = billingOptions.find(
          item => item.plan.includes(plan) && item.interval === 'year'
        );
        if (found) {
          return found.clicks;
        }
      } else if (plan === 'Pro') {
        return this.state.proClicks;
      }
    }
    return '';
  };

  updateClicks = e => {
    e.preventDefault();
    e.stopPropagation();
    const { billingOptions, selectedBilling = {} } = this.state;
    let clicks = parseInt(e.target.value, 10);
    if (clicks > 10000 && clicks < 25000) {
      clicks = 25000;
    } else if (clicks > 25000 && clicks < 50000) {
      clicks = 50000;
    } else if (clicks > 50000 && clicks < 75000) {
      clicks = 75000;
    } else if (clicks > 75000) {
      clicks = 100000;
    }
    this.setState({
      proClicks: clicks,
      selectedPlan: planOptions[0],
      selectedBilling: billingOptions.find(
        item =>
          item.plan === 'Pro' &&
          item.clicks === clicks &&
          item.interval === selectedBilling.interval &&
          item.interval_count === selectedBilling.interval_count
      )
    });
  };

  onClickNext = async () => {
    this.setState({ loading: true, formHasBeenSubmitted: true });
    const { selectedBilling, currency } = this.state;
    const { onClickNext } = this.props;
    try {
      await onClickNext(selectedBilling, currency.value);
      window.Intercom('trackEvent', 'register-step-2');
    } catch (error) {
      console.log(error);
      this.setState({ errors: { accountCreation: error.message }, loading: false });
    }
  };

  getPrice = price => {
    const { discount } = this.props;
    if (discount !== 0) {
      return price - (price * discount) / 100;
    }
    return price;
  };

  render() {
    const {
      selectedPlan,
      selectedBilling,
      loading,
      billingOptions,
      errors,
      proClicks,
      currency
    } = this.state;

    const { conversionRates, couponError, discount } = this.props;
    return (
      <div className={styles.formContainer}>
        <h1 className={styles.headerText}>Awesome! Keep going.</h1>
        {!!billingOptions.length && selectedBilling.id && (
          <div className={styles.chosePlanBox}>
            <div className={`${styles.secValue} ${styles.currencyWrap}`}>
              <div style={{ whiteSpace: 'nowrap' }}>
                Select Plan{' '}
                {discount !== 0 && (
                  <span className={styles.discountApplied}>
                    <img src={CouponTick} />
                    <strong>{discount}%</strong> discount applied!
                  </span>
                )}
              </div>
              <Dropdown
                options={currencyOptions}
                value={currency}
                selectClass={styles.currencySelect}
                name="currency"
                onOptionChange={value => this.onSelectOption('currency', value)}
              />
            </div>
            <div className={styles.radioBox}>
              <div
                className={`${styles.radioSec} ${
                  selectedBilling.plan === planOptions[1] ? styles.activeRadioSec : ''
                }`}
                onClick={() =>
                  this.changePlan(
                    billingOptions.find(
                      option =>
                        option.plan === planOptions[1] &&
                        option.interval === selectedBilling.interval &&
                        option.interval_count === 1
                    )
                  )
                }
              >
                <div className={styles.planType}>{planOptions[1]}</div>
                <div className={styles.planTypeSub}>7-day free trial</div>
                <div className={styles.planDuration}>
                  {currencySymbols[currency.value] || '$'}
                  {billingOptions.length &&
                    selectedBilling &&
                    selectedBilling.id &&
                    Utils.convertToCurrency(
                      conversionRates,
                      this.getPrice(
                        billingOptions.find(
                          item =>
                            item.plan === planOptions[1] &&
                            item.interval === selectedBilling.interval &&
                            item.interval_count === selectedBilling.interval_count
                        ).price
                      ),
                      currency.value
                    ).toLocaleString('en-US')}
                  <span>
                    {' '}
                    / {selectedBilling.interval_count === 3 ? 'quarter' : selectedBilling.interval}
                  </span>
                </div>
                <div className={styles.rangeSlider}>&nbsp;</div>
                <div className={styles.clicksAd}>
                  Up to{' '}
                  {this.getPlanClicks(planOptions[1]).toLocaleString('en-US', {
                    maximumFractionDigits: 1
                  })}{' '}
                  Ad Clicks
                </div>
                <div className={`${styles.clicksAd} ${styles.websites}`}>
                  {billingOptions &&
                    selectedBilling &&
                    selectedBilling.id &&
                    billingOptions.find(
                      item =>
                        item.plan === planOptions[1] &&
                        item.interval === selectedBilling.interval &&
                        item.interval_count === selectedBilling.interval_count
                    ).domains}{' '}
                  Website
                </div>
              </div>
              <div
                className={`${styles.radioSec} ${
                  selectedBilling.plan === planOptions[0] ? styles.activeRadioSec : ''
                }`}
                onClick={() =>
                  this.changePlan(
                    billingOptions.find(
                      option =>
                        option.plan === planOptions[0] &&
                        option.interval === selectedBilling.interval &&
                        option.interval_count === 1 &&
                        option.clicks === proClicks
                    )
                  )
                }
              >
                <div className={styles.planType}>{planOptions[0]}</div>
                <div className={styles.planTypeSub}>7-day free trial</div>
                <div className={styles.planDuration}>
                  {currencySymbols[currency.value] || '$'}
                  {billingOptions.length &&
                    selectedBilling &&
                    selectedBilling.id &&
                    Utils.convertToCurrency(
                      conversionRates,
                      this.getPrice(
                        billingOptions.find(
                          item =>
                            item.plan === planOptions[0] &&
                            item.interval === selectedBilling.interval &&
                            item.interval_count === selectedBilling.interval_count &&
                            item.clicks === proClicks
                        ).price
                      ),
                      currency.value
                    ).toLocaleString('en-US')}
                  <span>
                    {' '}
                    / {selectedBilling.interval_count === 3 ? 'quarter' : selectedBilling.interval}
                  </span>
                </div>
                <div className={styles.rangeSlider}>
                  <RangeSlider
                    markers={[10000, 20000, 30000, 40000, 50000]}
                    value={proClicks}
                    handleChange={this.updateClicks}
                  />
                </div>
                <div className={styles.clicksAd}>
                  Up to{' '}
                  <span>
                    {this.getPlanClicks(planOptions[0]).toLocaleString('en-US', {
                      maximumFractionDigits: 1
                    })}
                  </span>{' '}
                  Ad Clicks
                </div>
                {billingOptions.length && selectedBilling && selectedBilling.id ? (
                  billingOptions.find(
                    item =>
                      item.plan === planOptions[0] &&
                      item.interval === selectedBilling.interval &&
                      item.interval_count === selectedBilling.interval_count &&
                      item.clicks === proClicks
                  ).domains !== 'unlimited' ? (
                    <div className={`${styles.clicksAd} ${styles.websites}`}>
                      Up to{' '}
                      <span>
                        {
                          billingOptions.find(
                            item =>
                              item.plan === planOptions[0] &&
                              item.interval === selectedBilling.interval &&
                              item.interval_count === selectedBilling.interval_count &&
                              item.clicks === proClicks
                          ).domains
                        }
                      </span>{' '}
                      Websites
                    </div>
                  ) : (
                    <div className={`${styles.clicksAd} ${styles.websites}`}>
                      <span>Unlimited</span> Websites
                    </div>
                  )
                ) : null}
              </div>
            </div>
            <div className={styles.secValue}>Select Payment Term</div>
            <div className={`${styles.radioBox} ${styles.radioBoxPlan}`}>
              <div
                onClick={() =>
                  this.onSelectOption(
                    'selectedBilling',
                    billingOptions &&
                      billingOptions.find(
                        item =>
                          item.plan === selectedPlan &&
                          (selectedPlan === 'Starter' || item.clicks === proClicks) &&
                          item.interval === 'month' &&
                          item.interval_count === 1
                      )
                  )
                }
                className={`${styles.radioSec} ${
                  selectedBilling.interval_count === 1 && selectedBilling.interval === 'month'
                    ? styles.activeRadioSec
                    : ''
                }`}
              >
                <div className={styles.planTerms}>Monthly</div>
              </div>
              <div
                onClick={() =>
                  this.onSelectOption(
                    'selectedBilling',
                    billingOptions &&
                      billingOptions.find(
                        item =>
                          item.plan === selectedPlan &&
                          (selectedPlan === 'Starter' || item.clicks === proClicks) &&
                          item.interval === 'month' &&
                          item.interval_count === 3
                      )
                  )
                }
                className={`${styles.radioSec} ${
                  selectedBilling.interval_count === 3 && selectedBilling.interval === 'month'
                    ? styles.activeRadioSec
                    : ''
                }`}
              >
                <div className={styles.planTerms}>
                  <div className={styles.planTermsInner}>
                    Quarterly <span>(Save 12%)</span>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  this.onSelectOption(
                    'selectedBilling',
                    billingOptions &&
                      billingOptions.find(
                        item =>
                          item.plan === selectedPlan &&
                          (selectedPlan === 'Starter' || item.clicks === proClicks) &&
                          item.interval === 'year'
                      )
                  )
                }
                className={`${styles.radioSec} ${
                  selectedBilling.interval && selectedBilling.interval === 'year'
                    ? styles.activeRadioSec
                    : ''
                }`}
              >
                <div className={styles.planTerms}>
                  <div className={styles.planTermsInner}>
                    Annual <span>(Save 20%)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.secValue}>Select Payment Method</div>
            <div className={styles.radioBox}>
              <div
                onClick={() => this.onSelectOption('paymentMethod', 'CreditCard')}
                className={`${styles.radioSec} ${styles.activeRadioSec}`}
              >
                <div className={styles.planTerms}>
                  <img src={CardGreen} />
                  Credit Card
                </div>
              </div>
              <div className={styles.radioSec} style={customStyles.disabledRadio}>
                <div className={styles.planTerms}>
                  <img src={PaypalGrey} /> Paypal
                </div>
                <div className={styles.planTerms} style={customStyles.comingSoon}>
                  Coming Soon
                </div>
              </div>
            </div>
          </div>
        )}
        {(errors.accountCreation || couponError) && (
          <ErrorBox
            errorStyle={{ marginRight: '35px' }}
            error={errors.accountCreation || couponError}
          />
        )}
        {!!billingOptions.length && selectedBilling.id && (
          <div className={styles.formFooterContainer}>
            <Button
              title="Next"
              onClick={this.onClickNext}
              loading={loading}
              style={customStyles.nextBtn}
              customClassNames="acctCreation__nextBtn"
              color="green"
            />
          </div>
        )}
      </div>
    );
  }
}

AccountCreationForm.propTypes = {
  onClickNext: PropTypes.func,
  onClickBack: PropTypes.func,
  updateUser: PropTypes.func,
  updateUserAccount: PropTypes.func,
  user: PropTypes.object,
  accounts: PropTypes.object,
  currency: PropTypes.string,
  conversionRates: PropTypes.any,
  discount: PropTypes.number,
  couponError: PropTypes.string
};

export default AccountCreationForm;
