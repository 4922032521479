/* eslint-disable no-useless-escape */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';
import ReactTooltip from 'react-tooltip';
import styles from '../../RegisterSteps.module.scss';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import { ReactComponent as GREENCHECK } from '../../../../assets/green-check.svg';
import InputContainer from '../../../../components/InputContainer/InputContainer';
import ErrorBox from '../../../../components/ErrorBox/ErrorBox';
import Constants from '../../../../utils/Constants';
import Utils from '../../../../utils/Utils';
import { ReactComponent as TooltipIcon } from '../../../../assets/tooltip.svg';
import { ReactComponent as UnlockDiscountIcon } from '../../../../assets/unlock-discount.svg';
import Payments from '../../../../api/Payments';

const { currencySymbols } = Constants;

const customStyles = {
  input: {
    marginBottom: 25
  },
  zipInput: {
    width: 100,
    marginBottom: 25
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: '600',
    color: '#666666'
  },
  comingSoon: {
    paddingTop: '10px',
    marginBottom: '-10px',
    fontWeight: 500,
    fontSize: '10px'
  },
  disabledRadio: {
    background: '#f1f1f1',
    border: 'none',
    pointerEvents: 'none'
  },
  currencyDropdown: {
    width: '100px'
  }
};

class PaymentForm extends PureComponent {
  state = {
    cardName: '',
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    cardError: {},
    errors: {},
    loading: false,
    firstName: '',
    lastName: '',
    zip: '',
    isMobile: false
  };

  checkMobile = () => {
    let check = false;
    (a => {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    this.setState({ isMobile: check });
  };

  componentDidMount() {
    window.scrollTo(0, 100);
    ReactTooltip.rebuild();
    this.checkMobile();
    if (this.props.user) {
      this.setState({
        firstName: this.props.user.first_name || '',
        lastName: this.props.user.last_name
      });
    }
    window.addEventListener('resize', () => {
      this.checkMobile();
    });
  }

  onChangeText = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleChange = change => {
    // console.log('[change]', change);
    if (change.complete) {
      this.setState({ [change.elementType]: true });
    }
  };

  handleReady = () => {
    // console.log('[ready]');
  };

  onSubmitPayment = async (token, zip, name) => {
    const { accounts, onClickNext } = this.props;

    try {
      if (token) {
        const data = {
          source: token.id
        };
        if (zip) {
          data.address = {
            postal_code: zip
          };
        }
        if (name) {
          data.name = name;
        }
        await Payments.updateCustomer(accounts.data.stripe_token, data);
        window.Intercom('trackEvent', 'credit-card', {
          timestamp: new Date(),
          added: true,
          account: accounts.data.id
        });
      }
      onClickNext();
    } catch (error) {
      this.setState({ error: error.message });
      throw error;
    }
  };

  submit = async skipPayment => {
    const { cardNumber, cardExpiry, cardCvc, firstName, lastName, zip } = this.state;
    const { stripe, user, updateUser } = this.props;

    this.setState({ loading: true });
    try {
      if (firstName !== user.first_name || lastName !== user.last_name) {
        const updateUserData = {
          first_name: firstName,
          last_name: lastName
        };
        await updateUser(user.id, updateUserData);
        window.Intercom('update', {
          user_id: user.id,
          name: `${firstName} ${lastName}`
        });
      }
      if (!skipPayment) {
        if (cardNumber && cardExpiry && cardCvc && firstName.length > 0 && lastName.length > 0) {
          const { token } = await stripe.createToken({
            name: `${firstName} ${lastName}`,
            cardNumber,
            cardExpiry,
            cardCvc
          });
          if (!token) {
            throw Error('Please enter valid payment details to continue.');
          } else {
            await this.onSubmitPayment(token, zip, `${firstName} ${lastName}`);
          }
        } else {
          throw Error('Please enter valid payment details to continue.');
        }
      } else {
        await this.onSubmitPayment(null);
      }
      window.Intercom('trackEvent', 'register-step-5');
    } catch (error) {
      this.setState({ errors: { paymentError: error.message }, loading: false });
    }
  };

  /**
   * Subscribes user to their selected plan - could be default - for free trial.
   * Submit handled as usual.
   */
  onClickSkip = () => {
    this.submit(true);
  };

  getPrice = price => {
    const { discount } = this.props;
    if (discount !== 0) {
      return price - (price * discount) / 100;
    }
    return price;
  };

  getSelectedPlan = () => {
    const {
      accounts: {
        data: { initial_plan: defaultPlan }
      }
    } = this.props;
    if (defaultPlan) {
      return {
        amount: defaultPlan.price,
        interval: defaultPlan.interval,
        interval_count: defaultPlan.interval_count
      };
    }
    return { interval: 'month', amount: 0, interval_count: 1 };
  };

  render() {
    const { loading, errors, firstName, lastName, zip } = this.state;
    const { onClickBack, currency, conversionRates, discount } = this.props;
    const { interval, amount, interval_count: intervalCount } = this.getSelectedPlan();
    return (
      <div className={`${styles.slideLeft} ${styles.planBox}`}>
        <ReactTooltip id="cvvNote">
          3-digit code on back of card (4 digits for AMEX cards)
        </ReactTooltip>
        <h2 className={styles.headingPlan}>
          Awesome! <span>This is your final step</span>
        </h2>
        <div className={styles.planSection}>
          <div className={styles.planValue}>
            <form autoComplete="off">
              <Input
                name="firstName"
                value={firstName}
                onChange={this.onChangeText}
                labelStyle={customStyles.inputLabel}
                containerStyle={customStyles.input}
                label="First Name on Card"
                autoSuggest={false}
              />
              <Input
                name="lastName"
                value={lastName}
                onChange={this.onChangeText}
                labelStyle={customStyles.inputLabel}
                containerStyle={customStyles.input}
                label="Last Name on Card"
                autoSuggest={false}
              />
            </form>
            <InputContainer
              labelStyle={customStyles.inputLabel}
              containerStyle={customStyles.input}
              label="Card Number"
            >
              <CardNumberElement onChange={this.handleChange} onReady={this.handleReady} />
            </InputContainer>
            <div className={styles.twoInputsContainer}>
              <InputContainer
                labelStyle={customStyles.inputLabel}
                containerStyle={customStyles.input}
                label="Expiry Date"
              >
                <CardExpiryElement onChange={this.handleChange} onReady={this.handleReady} />
              </InputContainer>
              <InputContainer
                labelStyle={customStyles.inputLabel}
                containerStyle={customStyles.input}
                label={
                  <span>
                    CVV{' '}
                    <TooltipIcon className={styles.registerHelpTip} data-tip data-for="cvvNote" />
                  </span>
                }
              >
                <CardCVCElement onChange={this.handleChange} onReady={this.handleReady} />
              </InputContainer>
              <Input
                name="zip"
                value={zip}
                pattern="[A-Za-z\d\s]+"
                onChange={this.onChangeText}
                containerStyle={customStyles.zipInput}
                labelStyle={customStyles.inputLabel}
                label="ZIP / Postal"
              />
            </div>
            {errors.paymentError && (
              <ErrorBox errorStyle={{ marginRight: '35px' }} error={errors.paymentError} />
            )}
          </div>
          <div className={`${styles.planDesc} ${styles.finalStepDesc}`}>
            <div className={styles.planInfo}>
              <>
                {discount !== 0 && (
                  <div className={`${styles.discountApplied} ${styles.discountSticky}`}>
                    <UnlockDiscountIcon />
                    {discount}% discount applied!
                  </div>
                )}
                <div className={styles.payAmount}>Payment Amount</div>
                <div className={styles.planAmount}>
                  {currencySymbols[currency] || '$'}
                  {Utils.convertToCurrency(
                    conversionRates,
                    this.getPrice(amount),
                    currency
                  ).toLocaleString('en-US')}
                  <span>/{intervalCount === 3 ? 'quarter' : interval}</span>
                </div>
                <div className={styles.planFeature}>
                  <ul>
                    <li>
                      <strong>Amount due today - {currencySymbols[currency] || '$'}0</strong>Your
                      card will not be charged until the end of your 7-day free trial period.
                    </li>

                    <li>
                      <strong>No contracts</strong>Downgrade or cancel anytime from your dashboard.
                    </li>
                  </ul>
                </div>
              </>
            </div>
          </div>
        </div>
        <div className={styles.buttonFinal}>
          <Button title="Back" type="button" onClick={onClickBack} color="back" />
          <div>
            <Button
              title="Start 7-Day Free Trial"
              type="button"
              loading={loading}
              customClassNames="paymentForm__nextBtn"
              onClick={this.submit}
              color="new-green"
            />
            <div className={styles.sslInfo}>This is a secure SSL encrypted payment.</div>
          </div>
        </div>
      </div>
    );
  }
}

PaymentForm.propTypes = {
  onClickNext: PropTypes.func,
  onClickBack: PropTypes.func,
  stripe: PropTypes.any,
  conversionRates: PropTypes.any,
  currency: PropTypes.string,
  user: PropTypes.object,
  updateUser: PropTypes.func,
  accounts: PropTypes.any,
  discount: PropTypes.number
};

class SelectOption extends PureComponent {
  onClick = () => {
    const { onSelect, type, value } = this.props;
    onSelect(type, value);
  };

  render() {
    const { selected, children, customStyle } = this.props;
    const style = selected ? styles.selectContainerActive : styles.selectContainer;
    return (
      <div className={style} style={customStyle} onClick={this.onClick}>
        {children}
        {selected && <GREENCHECK className={styles.greenCheck} />}
      </div>
    );
  }
}

SelectOption.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.node,
  onSelect: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customStyle: PropTypes.any
};

export default injectStripe(PaymentForm);
