import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Navigation.module.scss';
import menu from './menu-options';
import NavItem from './NavItem';
// import Data from '../../api/Data';
import Dropdown from '../../components/Dropdown/Dropdown';
import { ReactComponent as MainLogo } from '../../assets/main-logo.svg';
import CurrentUserBox from '../CurrentUserBox/CurrentUserBox';
import OverviewIcon from '../../assets/overview-icon.svg';

const customStyles = {
  dropdownContainer: {
    borderRadius: '5px'
  },
  dropdownLabel: {
    fontSize: '11px',
    color: '#2b2c33'
  }
};

class Navigation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      domainOptions: []
    };
  }

  // fetchSiteClicks = async sid => {
  //   try {
  //     const result = await Data.getSiteClicks({
  //       sid
  //     });
  //     console.log('Clicks', result);
  //     if (result && !result.errno) {
  //       return result[0] ? result[0].clicks : 0;
  //     }
  //     return 0;
  //   } catch (error) {
  //     console.log(error);
  //     return 0;
  //   }
  // };

  onClickAddDomain = () => {
    console.log('Add Domain');
    this.setState({ showAddDomainModal: true });
  };

  toggleDomainSuccessModal = () => {
    const { showDomainSuccessModal } = this.state;
    this.setState({ showDomainSuccessModal: !showDomainSuccessModal, showAddDomainModal: false });
  };

  async componentDidMount() {
    if (
      this.props.accounts &&
      this.props.accounts.data &&
      this.props.accounts.data.domains &&
      this.props.accounts.data.domains.length
    ) {
      const optionsDefault = this.props.accounts.data.domains
        .sort((a, b) => {
          if (a.domain_name.toLowerCase() < b.domain_name.toLowerCase()) {
            return -1;
          }
          if (a.domain_name.toLowerCase() > b.domain_name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .filter(item => item.is_deleted === false)
        .map(item => ({
          value: item.id,
          label: item.domain_name
        }));
      this.setState({ domainOptions: optionsDefault });
      // this.props.setDomain(this.props.accounts.data.domains[0]);
    }
  }

  render() {
    const { auth, location, history, activeDomain, accounts, setDomain } = this.props;
    return (
      <div className={styles.vertical}>
        <div className={styles.logoWrap}>
          <MainLogo className={styles.logo} />
        </div>
        <div className={styles.externalMenu}>
          <div className={styles.menuLabel}>All Websites</div>
          <Link className={styles.navItemTitle} to="/overview">
            <img className={styles.overviewMenuIcon} src={OverviewIcon} />
            <p>Overview</p>
          </Link>
        </div>
        <section className={styles.navBlock}>
          <Dropdown
            containerStyle={customStyles.dropdownContainer}
            labelStyle={customStyles.dropdownLabel}
            selectClass={styles.dropdown}
            label="Single Website"
            name="activeDomain"
            onOptionChange={val =>
              setDomain(accounts.data.domains.find(item => item.id === val.value))
            }
            value={
              activeDomain && activeDomain.data
                ? { value: activeDomain.data.id, label: activeDomain.data.domain_name }
                : null
            }
            options={this.state.domainOptions}
          />
        </section>
        {menu.navItems.map(item => {
          return (
            <NavItem
              key={item.title}
              item={item}
              userRole={auth.user.role}
              activeDomain={activeDomain}
              isDisabled={
                !!(
                  item.protected &&
                  (auth.user.role === 'Viewer' ||
                    (item.blockForRoles && item.blockForRoles.includes(auth.user.role)))
                )
              }
              location={location}
              history={history}
              isExternal={item.isExternal}
            />
          );
        })}
        {auth.user && (
          <CurrentUserBox
            user={auth.user}
            history={history}
            activeDomain={activeDomain}
            accounts={accounts}
          />
        )}
      </div>
    );
  }
}

Navigation.propTypes = {
  auth: PropTypes.object,
  accounts: PropTypes.object,
  activeDomain: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  setDomain: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts,
  activeDomain: state.activeDomain
});

export default connect(mapStateToProps)(Navigation);
